define("monitor-ui/translations/en-us", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    "app": {
      "warningMessages": {
        "followedLinkExpired": "The link you have followed has expired."
      }
    },
    "price_banner": "The {product} costs {price, number, USD}"
  };
  _exports.default = _default;
});