define("monitor-ui/controllers/app/projects/project/evaluations/evaluation", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/service", "ember-concurrency-decorators", "ember-keyboard", "ember-concurrency"], function (_exports, _decorate2, _object, _service, _emberConcurrencyDecorators, _emberKeyboard, _emberConcurrency) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectEvaluationsEvaluationController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsProjectEvaluationsEvaluationController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectEvaluationsEvaluationController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "sessionContext",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "currentUser",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('evaluation.externalSurveyUrl', 'userEvaluationSurvey')],
        key: "typeformUrl",
        value: function typeformUrl() {
          const userId = this.get('userEvaluationSurvey.userUuid');
          const evaluationId = this.get('userEvaluationSurvey.evalUuid');
          return `${this.evaluation.externalSurveyUrl}?userid=${userId}&evaluationid=${evaluationId}`;
        }
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('userEvaluationSurvey.status')],
        key: "isSubmitted",
        value: function isSubmitted() {
          if (this.userEvaluationSurvey.status === 'submitted') {
            return true;
          }

          return false;
        }
      }, {
        kind: "field",
        key: "isSubmitting",

        value() {
          return false;
        }

      }, {
        kind: "field",
        key: "showThankYouScreen",

        value() {
          return false;
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "nextQuestion",
        value: function nextQuestion(paramQuestion) {
          if (paramQuestion) return this.survey.surveyQuestions.findBy('order', paramQuestion.order + 1);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "getOrCreateAnswer",
        value: function getOrCreateAnswer(question, answer) {
          var savedAnswer = this.allQuestionsAnswers.findBy('surveyQuestion.id', question.id);

          if (!savedAnswer) {
            savedAnswer = this.store.createRecord('surveyAnswer', {
              surveyQuestion: question,
              user: this.user,
              userEvaluationSurvey: this.userEvaluationSurvey
            });
          }

          switch (question.type) {
            case 'input':
              if (answer && answer.trim() === '') {
                savedAnswer.set('text', null);
              } else {
                savedAnswer.set('text', answer);
              }

              break;

            case 'likert-scale':
              savedAnswer.set('questionChoice', answer);
              savedAnswer.send('becomeDirty');
              break;
          }

          return savedAnswer;
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "sendAnswer",
        value: function sendAnswer(question, answer, next) {
          var savedAnswer = this.getOrCreateAnswer(question, answer);
          this.allQuestionsAnswers.addObject(savedAnswer);
          this.saveAll.perform();
          const nextCurrentQuestion = this.nextQuestion(question);

          if (next) {
            if (nextCurrentQuestion) {
              this.setCurrentQuestionBox.perform(nextCurrentQuestion, question);
            } else {
              this.transitionToAnotherQuestion.perform();
            }
          }
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "setCurrentQuestionBox",
        value: function* setCurrentQuestionBox(currentQuestion, oldQuestion) {
          try {
            if (!oldQuestion) {
              this.transitionToAnotherQuestion.perform(currentQuestion);
            } else {
              this.transitionToAnotherQuestion.perform(oldQuestion, currentQuestion);
            }

            Ember.set(this, 'activeQuestion', currentQuestion);
          } catch (e) {}
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "transitionToAnotherQuestion",
        value: function* transitionToAnotherQuestion(currentQuestion, nextQuestion) {
          try {
            if (!(nextQuestion || currentQuestion)) {
              $('html, body').animate({
                scrollTop: $('#submit-btn').offset().top - 100
              });
              $('#submit-btn').focus();
              Ember.set(this, 'activeQuestion', null);
            } else {
              let targetQuestion = nextQuestion ? nextQuestion : currentQuestion;

              if (nextQuestion && currentQuestion.type === 'likert-scale') {
                yield (0, _emberConcurrency.timeout)(700);
              }

              $('html, body').animate({
                scrollTop: $('#question-' + targetQuestion.id).offset().top - 100
              });
            }
          } catch (e) {}
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "submitForm",
        value: function* submitForm() {
          try {
            this.set('isSubmitting', true);
            const result = yield this.evaluation.submitUserEvaluation({
              survey_id: this.survey.id,
              'evaluation_session_id': this.evaluation.id
            });

            if ('status' in result.data && result.data.status === 'completed') {
              if (this.survey.redirectUrl) {
                window.location.href = this.survey.redirectUrl;
              }

              this.set('showThankYouScreen', true);
            }

            this.set('isSubmitting', false);
          } catch (e) {
            this.set('isSubmitting', false);
            e.errors.forEach(error => {
              if (error.status == 500) {
                this.notify.error('An error occured. Please try again later');
              } else {
                this.notify.error(error.detail);
              }

              if (error.detail === 'Incomplete questions') {
                Ember.set(this, 'remainingQuestions', []);

                for (let question_id of error.data) {
                  this.remainingQuestions.push(question_id);
                }

                $('html, body').animate({
                  scrollTop: $('#question-' + this.remainingQuestions[0]).offset().top - 100
                }, 900);
              } else if (error.detail === 'End date of evaluation session was exceeded') {
                this.transitionToRoute('app.projects.list');
              }
            });
          }
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "saveAll",
        value: function* saveAll() {
          let promiseArray = [];
          this.allQuestionsAnswers.toArray().forEach(function (item) {
            if (item.hasDirtyAttributes) {
              promiseArray.addObject(item.save());
            }
          });
          return yield Promise.all(promiseArray).then(function () {}).catch(function (err) {});
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "autoSaveAll",
        value: function* autoSaveAll() {
          this.saveAll();
          yield (0, _emberConcurrency.timeout)(10000);
          this.autoSaveAll();
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsProjectEvaluationsEvaluationController;
});