define("monitor-ui/controllers/app/projects/project/surveys", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "ember-concurrency-decorators", "@ember-decorators/service"], function (_exports, _decorate2, _object, _emberConcurrencyDecorators, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectSurveysController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsProjectSurveysController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectSurveysController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('projectPerms', 'currentUser.isMonitorAdmin')],
        key: "isAdmin",
        value: function isAdmin() {
          return !!this.projectPerms.findBy('role', 'admin');
        }
      }, {
        kind: "field",
        key: "willAddSurvey",

        value() {
          return false;
        }

      }, {
        kind: "field",
        key: "currentClonedSurvey",

        value() {
          return null;
        }

      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "cloneSurvey",
        value: function* cloneSurvey(survey, title) {
          if (!title) {
            this.notify.error('Title is required');
          } else {
            yield survey.cloneSurvey({
              'surveyTitle': title,
              'projectId': this.project.id
            });
            this.set('inputCloneValue', '');
            this.set('currentClonedSurvey', null);
            this.reloadSurveyList();
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "openCloneSurveyModal",
        value: function openCloneSurveyModal(survey) {
          this.set('currentClonedSurvey', survey);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "reloadSurveyList",
        value: function reloadSurveyList() {
          this.send('reloadModel');
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "addSurvey",
        value: function addSurvey(title) {
          if (!title) {
            this.notify.error('Title is required');
          } else {
            this.set('willAddSurvey', false);
            this.set('inputValue', '');
            const survey = this.store.createRecord('survey', {
              title: title,
              welcomeMessage: 'Welcome',
              project: this.project
            });
            survey.save().then(() => {
              this.transitionToRoute('app.surveys.survey', survey.id);
            }).catch(function (err) {
              console.log('Error', err);
            });
          }
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsProjectSurveysController;
});