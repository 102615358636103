define("monitor-ui/controllers/app/projects/project/evaluations/evaluation-details", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/object/computed"], function (_exports, _decorate2, _object, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectEvaluationsEvaluationDetailsController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsProjectEvaluationsEvaluationDetailsController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectEvaluationsEvaluationDetailsController,
      d: [{
        kind: "field",
        decorators: [(0, _computed.filterBy)('userEvaluationSurveys', 'status', 'submitted')],
        key: "submittedSurveys",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('submittedSurveys.length', 'userEvaluationSurveys.length')],
        key: "completedPercentage",
        value: function completedPercentage() {
          if (this.submittedSurveys) {
            return (this.submittedSurveys.length / this.userEvaluationSurveys.length * 100).toFixed(2);
          }

          return 0;
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsProjectEvaluationsEvaluationDetailsController;
});