define("monitor-ui/routes/app/organisation", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppOrganisationRoute extends Ember.Route {}

  _exports.default = AppOrganisationRoute;
});