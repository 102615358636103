define("monitor-ui/utils/session-manager", ["exports", "monitor-ui/utils/login"], function (_exports, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = _exports.SessionManager = void 0;

  class SessionManager {
    constructor() {
      this.REFRESH_TOKEN_KEY = 'jwtRefreshToken';
      this.ACCESS_TOKEN_KEY = 'jwtAccessToken';
      this.EMBER_TOKEN = 'ember_simple_auth:session';
      this.tenants = [];
      this.accessToken = null;
      this.refreshToken = null;
      this.load();
    }

    async switchToCrossTenants(targetUrl = null) {
      await (0, _login.refreshCall)(this.getRefreshToken()).then(data => {
        this.setAccessToken(data.accessToken);
      });

      if (targetUrl) {
        window.location.href = targetUrl;
        return;
      }

      const url = new URL(window.location.href);
      const hash = url.hash; // Preserve the existing hash

      url.hash = ''; // Temporarily clear hash to avoid duplicating it
      // Set the hash back to the URL

      window.location.href = `${url.origin}${url.pathname}${hash}`;
    }

    async switchTenant(tenantId, targetUrl = null) {
      await (0, _login.refreshCall)(this.getRefreshToken(), tenantId).then(data => {
        this.setAccessToken(data.accessToken);
      });

      if (targetUrl) {
        window.location.href = targetUrl;
        return;
      }

      const url = new URL(window.location.href);
      url.searchParams.set('tenantID', tenantId);
      const hash = url.hash; // Preserve the existing hash

      url.hash = ''; // Temporarily clear hash to avoid duplicating it
      // Set the hash back to the URL

      window.location.href = `${url.origin}${url.pathname}${url.search}${hash}`;
    }

    save() {
      if (!this.refreshToken) {
        window.localStorage.removeItem(this.REFRESH_TOKEN_KEY);
      } else {
        window.localStorage.setItem(this.REFRESH_TOKEN_KEY, this.refreshToken);
      }

      if (!this.accessToken) {
        window.localStorage.removeItem(this.ACCESS_TOKEN_KEY);
      } else {
        window.localStorage.setItem(this.ACCESS_TOKEN_KEY, this.accessToken);
      }
    }

    getAccessToken() {
      if (!this.accessToken) {
        this.load();
      }

      return this.accessToken;
    }

    getAccessTokenPayload() {
      return (0, _login.jwtPayload)(this.getAccessToken());
    }

    setAccessToken(token) {
      this.accessToken = token;
      this.save();
    }

    getRefreshToken() {
      if (!this.refreshToken) {
        this.load();
      }

      return this.refreshToken;
    }

    getRefreshTokenPayload() {
      return (0, _login.jwtPayload)(this.getRefreshToken());
    }

    setRefreshToken(token) {
      this.refreshToken = token;
      this.save();
    }

    load() {
      this.refreshToken = window.localStorage.getItem(this.REFRESH_TOKEN_KEY);
      this.accessToken = window.localStorage.getItem(this.ACCESS_TOKEN_KEY);
    }

    loadFromQueryParams(queryParams) {
      if ('tcbToken' in queryParams) {
        if ('tcbRefreshToken' in queryParams) {
          this.refreshToken = queryParams.tcbRefreshToken;
        }

        this.accessToken = queryParams.tcbToken;
        this.save();
      }
    }

    logout() {
      this.tenants = [];
      this.accessToken = null;
      this.refreshToken = null;
      window.localStorage.removeItem(this.EMBER_TOKEN);
      this.save();
    }

    isLoggedIn() {
      return this.accessToken !== null;
    }

    getCurrentTenantId() {
      const tokenPayload = this.getAccessTokenPayload();
      return tokenPayload ? tokenPayload.active_tenant_id : null;
    }

    getCrossTenants() {
      const tokenPayload = this.getAccessTokenPayload();
      return tokenPayload ? tokenPayload.cross_tenant_ids : [];
    }

    static getTokenPayload(token) {
      return (0, _login.jwtPayload)(token);
    }

    computeUrlFromTransition(transition) {
      if (!transition) return null; // extract the url from the transition
      // for some transitions, the route url comes ready made in the transition.intent.url
      // otherwise we compute it from the transition.intent.name (we also need to prefix a '/' to it) and the router mapping

      const recognizer = transition.router.recognizer.names;
      let url;
      let staticSegments;

      if (transition.intent.name) {
        // the segments prop in the recognizer is an array of objects like these { type: int, value: string }
        staticSegments = recognizer[transition.intent.name].segments.filter(segment => segment.type === 0);
        url = `${window.location.origin}#/${staticSegments.map(segment => segment.value).join('/')}`;
      } else {
        url = `${window.location.origin}#${transition.intent.url}`;
      }

      if (!transition.intent.url) {
        // the transition.intent.url has the dynamic segments and query params already set
        // Add dynamic segments
        for (const [key, value] of Object.entries(transition.params || {})) {
          const paramKeys = Object.keys(value);
          const routePaths = key.replaceAll('.index.', '.').replaceAll('.index', '').split('.');
          const targetRouteIndex = routePaths.length - 1;

          if (targetRouteIndex !== -1) {
            const targetRouteSegment = staticSegments[targetRouteIndex] ? staticSegments[targetRouteIndex].value : null;

            if (targetRouteSegment) {
              paramKeys.forEach(paramKey => {
                url = url.replace(`${targetRouteSegment}`, `${targetRouteSegment}/${value[paramKey]}`);
              });
            } else {
              // in this case, the route ends with a dynamic segment; we just append the dynamic segments at the end
              // e.g. app.templates.edit where edit has the path of /:template_id which is dynamic
              // so in won't appear in the staticSegments array
              url = url + `/${value[paramKeys[0]]}`;
            }
          }
        } // Add query parameters


        const queryParams = Object.entries(transition.queryParams || {});

        if (queryParams.length > 0) {
          url += '?';
          url += queryParams.map(([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`).join('&');
        }
      }

      return url;
    }

  }

  _exports.SessionManager = SessionManager;
  window.SessionManager = new SessionManager();
  var _default = window.SessionManager;
  _exports.default = _default;
});