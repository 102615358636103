define("monitor-ui/components/typeform-embed", ["exports", "@babel/runtime/helpers/esm/decorate", "@typeform/embed", "@ember-decorators/component"], function (_exports, _decorate2, typeformEmbed, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  // import FitToWindow from '../mixins/fit-to-window'
  let TypeformEmbedComponent = (0, _decorate2.default)([(0, _component.tagName)('div'), (0, _component.classNames)('w-100 typeform-container')], function (_initialize, _EmberComponent) {
    class TypeformEmbedComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: TypeformEmbedComponent,
      d: [{
        kind: "method",
        key: "didInsertElement",
        value: function didInsertElement() {
          const self = this;
          const options = {
            mode: 'popup',
            autoClose: 3000,
            hideHeaders: true,
            hideFooters: true,
            buttonText: 'Start the evaluation',
            onSubmit: function () {
              //call on submit
              if (self.onSubmit) {
                self.onSubmit();
              }
            }
          };
          const element = this.element; // typeformEmbed.makeWidget(element, this.url, options)

          const form = typeformEmbed.makePopup(this.url, options);
          form.open();
        }
      }]
    };
  }, Ember.Component);
  _exports.default = TypeformEmbedComponent;
});