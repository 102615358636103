define("monitor-ui/routes/app/projects/project/edit", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectEditRoute extends Ember.Route {
    model() {
      return Ember.RSVP.hash({
        projectStandards: this.store.findAll('service-standard'),
        industryStandards: this.store.findAll('industry-standard'),
        companyQuotes: this.store.findAll('quote-monitor-lite')
      });
    }

    setupController(controller, model) {
      const routeModel = this.modelFor('app.projects.project');
      controller.set('project', routeModel.project);
      controller.set('isEditting', false);
      controller.set('selectedApaduaProject ', null);
      controller.set('projectStandards', model.projectStandards.toArray());
      controller.set('industryStandards', model.industryStandards.toArray());
      controller.set('projects', model.companyQuotes.toArray());
      controller.setupChangeset();
    }

  }

  _exports.default = AppProjectsProjectEditRoute;
});