define("monitor-ui/routes/app/projects/project/evaluations/evaluation", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object"], function (_exports, _decorate2, _service, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectEvaluationsEvaluationRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute) {
    class AppProjectsProjectEvaluationsEvaluationRoute extends _EmberRoute {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectEvaluationsEvaluationRoute,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "method",
        key: "model",
        value: function model(params) {
          return Ember.RSVP.hash({
            evaluationSession: this.store.find('evaluation-session', params.evaluation_session_id)
          }).then(model => {
            let surveyId = model.evaluationSession.belongsTo('survey').id();
            return Ember.RSVP.hash({
              evaluationSession: model.evaluationSession,
              userEvaluationSession: model.evaluationSession.startUserEvaluation(),
              survey: this.store.findRecord('survey', surveyId, {
                include: 'questions.choices'
              })
            });
          }).then(model => {
            return Ember.RSVP.hash({
              evaluationSession: model.evaluationSession,
              userEvaluationSession: model.userEvaluationSession,
              survey: model.survey,
              allQuestionsAnswers: this.store.query('survey-answer', {
                user_evaluation_session: model.userEvaluationSession.data.id
              })
            });
          });
        }
      }, {
        kind: "method",
        key: "setupController",
        value: function setupController(controller, model) {
          controller.set('evaluation', model.evaluationSession);
          const userEvaluationSessionResponse = model.userEvaluationSession;
          this.store.pushPayload(userEvaluationSessionResponse);
          const record = this.store.peekRecord('user-evaluation-survey', userEvaluationSessionResponse.data.id);
          controller.set('userEvaluationSurvey', record); // //TODO: check what can be done to access it like an ember model

          controller.set('isSubmitted', model.userEvaluationSession.data.attributes.status === 'submitted');
          controller.set('survey', model.survey);
          controller.set('activeQuestion', model.survey.surveyQuestions.findBy('order', 1));
          controller.set('user', this.sessionContext.currentUser);
          controller.set('project', this.modelFor('app.projects.project').project);
          controller.set('remainingQuestions', []);
          controller.set('allQuestionsAnswers', model.allQuestionsAnswers.toArray());
          controller.set('showThankYouScreen', false);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "error",
        value: function error(_error, transition) {
          if (_error) {
            _error.errors.forEach(error => {
              if (error.status && error.status === '400') {
                alert("Evaluation session has expired");
                this.transitionTo('app.projects.list');
              }
            });
          }

          return true;
        }
      }]
    };
  }, Ember.Route);
  _exports.default = AppProjectsProjectEvaluationsEvaluationRoute;
});