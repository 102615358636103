define("monitor-ui/routes/app/projects/project/evaluations/evaluation-details", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectEvaluationsEvaluationDetailsRoute extends Ember.Route {
    model(params, transition) {
      //TODO: refactor in 1 request
      const projectId = this.modelFor('app.projects.project').project.id;
      return Ember.RSVP.hash({
        evaluationSession: this.store.find('evaluation-session', params.evaluation_session_id)
      }).then(model => {
        return Ember.RSVP.hash({
          evaluationSession: model.evaluationSession,
          userEvaluationSurveys: this.store.query('user-evaluation-survey', {
            evaluation_session_id: params.evaluation_session_id,
            project_id: projectId
          }, {
            include: 'user'
          }),
          group: this.store.findRecord('group', model.evaluationSession.belongsTo('group').id(), {
            include: 'users'
          })
        });
      });
    }

    setupController(controller, model) {
      const evaluation = model.evaluationSession;
      controller.set('evaluationSession', evaluation);
      controller.set('userEvaluationSurveys', model.userEvaluationSurveys);
      controller.set('group', model.group);
    }

  }

  _exports.default = AppProjectsProjectEvaluationsEvaluationDetailsRoute;
});