define("monitor-ui/models/survey-answer", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    text: attr(),
    surveyQuestion: belongsTo('survey-question', {
      inverse: 'surveyAnswers'
    }),
    questionChoice: belongsTo('question-choice', {
      inverse: 'surveyAnswers'
    }),
    user: belongsTo('user', {
      inverse: 'surveyAnswers'
    }),
    userEvaluationSurvey: belongsTo('user-evaluation-survey', {
      inverse: 'surveyAnswers'
    })
  });

  _exports.default = _default;
});