define("monitor-ui/templates/app/surveys/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "cCvZj6hK",
    "block": "{\"symbols\":[\"survey\"],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card mb-5 mt-3\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-header\"],[9],[0,\"\\n      Surveys\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n      \"],[7,\"ul\"],[11,\"class\",\"list-group list-group-flush\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"model\"]]],null,{\"statements\":[[0,\"          \"],[7,\"li\"],[11,\"class\",\"list-group-item\"],[9],[0,\"    \"],[4,\"link-to\",[\"app.surveys.survey\",[22,1,[\"id\"]]],null,{\"statements\":[[0,\"\\n            \"],[1,[22,1,[\"title\"]],false],[0,\"\\n          \"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/app/surveys/list.hbs"
    }
  });

  _exports.default = _default;
});