define("monitor-ui/templates/error", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "NFveiplm",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"row justify-content-center\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-12 col-md-5 col-xl-4 my-5\"],[9],[0,\"\\n\\n    \"],[7,\"div\"],[11,\"class\",\"text-center\"],[9],[0,\"\\n\\n      \"],[7,\"h6\"],[11,\"class\",\"text-uppercase text-muted mb-4\"],[9],[0,\"\\n        404 error\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"h1\"],[11,\"class\",\"display-4 mb-3\"],[9],[0,\"\\n        There’s no page here\\n      \"],[10],[0,\"\\n\\n      \"],[7,\"p\"],[11,\"class\",\"text-muted mb-4\"],[9],[0,\"\\n        Looks like you ended up here by accident?\\n      \"],[10],[0,\"\\n\\n      \"],[4,\"link-to\",[\"index\"],[[\"class\"],[\"btn btn-lg btn-primary\"]],{\"statements\":[[0,\"Return to your dashboard\"]],\"parameters\":[]},null],[0,\"\\n\\n    \"],[10],[0,\"\\n\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/error.hbs"
    }
  });

  _exports.default = _default;
});