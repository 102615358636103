define("monitor-ui/controllers/otp-login", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "jquery", "monitor-ui/config/environment"], function (_exports, _decorate2, _service, _object, _jquery, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let OtpLoginController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class OtpLoginController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: OtpLoginController,
      d: [{
        kind: "field",
        key: "queryParams",

        value() {
          return ['otp_required_token'];
        }

      }, {
        kind: "field",
        key: "otp_required_token",

        value() {
          return null;
        }

      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        key: "loginData",

        value() {
          return Ember.Object.extend({
            otp_code: null,
            otp_required_token: null
          }).create();
        }

      }, {
        kind: "field",
        key: "resendOtpTokenEndpoint",

        value() {
          return _environment.default.backendAPI + _environment.default['simple-token'].resendOtpTokenEndpoint;
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "otpLogin",
        value: function otpLogin(loginData) {
          loginData.otp_required_token = this.otp_required_token;

          if (this.theme.companySettings) {
            loginData.company = this.theme.companySettings.id;
          }

          return this.session.authenticate('authenticator:token', loginData).then(() => {}, response => {
            alert('Invalid OTP code');
          }).catch(() => {
            alert('Invalid OTP code');
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "resendOtp",
        value: function resendOtp() {
          return new Ember.RSVP.Promise((resolve, reject) => {
            _jquery.default.ajax({
              url: this.resendOtpTokenEndpoint,
              type: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
                'Authorization': 'Bearer null'
              },
              data: JSON.stringify({
                current_otp_token: this.otp_required_token
              }),
              dataType: 'json'
            }).then((data, statusText, response) => {
              if (response.status !== 200) {
                reject(response);
              }

              this.notify.success('OTP code resent');
              resolve(response);
            }, response => {
              this.notify.error('Error while sending OTP code');
              reject(response);
            });
          });
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = OtpLoginController;
  ;
});