define("monitor-ui/models/service-standard", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _emberData.default.Model.extend({
    serviceEn: _emberData.default.attr('string'),
    serviceDe: _emberData.default.attr('string'),
    parent: _emberData.default.belongsTo('service-standard', {
      async: true,
      inverse: 'children'
    }),
    children: _emberData.default.hasMany('service-standard', {
      async: true,
      inverse: 'parent'
    }),
    mpttOrder: _emberData.default.attr('string')
  });

  _exports.default = _default;
});