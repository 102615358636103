define("monitor-ui/templates/components/survey-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "HR0ecj01",
    "block": "{\"symbols\":[\"question\",\"@setCurrentQuestionBox\",\"@sendAnswerFocusOut\",\"@questionAnswers\",\"@activeQuestion\",\"@submitForm\"],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center h1 p-2 m-3 survey-title-box\"],[9],[1,[23,[\"survey\",\"title\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text-center h4 p-2 m-3 survey-title-box\"],[9],[1,[23,[\"survey\",\"welcomeMessage\"]],false],[10],[0,\"\\n\"],[7,\"div\"],[11,\"class\",\"text-center h4 p-2 m-3 survey-title-box\"],[9],[1,[23,[\"survey\",\"description\"]],false],[10],[0,\"\\n\\n\"],[4,\"each\",[[27,\"sort-by\",[\"order\",[23,[\"survey\",\"surveyQuestions\"]]],null]],null,{\"statements\":[[0,\"  \"],[5,\"question-view\",[],[[\"@question\",\"@activeQuestion\",\"@remainingQuestions\",\"@setCurrentQuestionBox\"],[[22,1,[]],[21,\"activeQuestion\"],[21,\"remainingQuestions\"],[22,2,[]]]],{\"statements\":[[0,\"\\n\\n\\n    \"],[1,[27,\"component\",[[27,\"concat\",[\"question-answers/\",[22,1,[\"type\"]]],null]],[[\"question\",\"sendAnswer\",\"activeQuestion\",\"questionAnswers\",\"sendAnswerFocusOut\"],[[22,1,[]],[23,[\"sendAnswer\"]],[22,5,[]],[22,4,[]],[22,3,[]]]]],false],[0,\"\\n\\n  \"]],\"parameters\":[]}],[0,\"\\n\"]],\"parameters\":[1]},null],[7,\"div\"],[11,\"class\",\"text-center p-2 m-3 survey-title-box\"],[9],[0,\"\\n  \"],[7,\"hr\"],[9],[10],[0,\"\\n  \"],[7,\"button\"],[11,\"id\",\"submit-btn\"],[11,\"class\",\"btn  btn-primary btn-lg \"],[12,\"disabled\",[21,\"isSubmitting\"]],[9],[0,\"Submit\"],[3,\"action\",[[22,0,[]],[22,6,[]]]],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/survey-view.hbs"
    }
  });

  _exports.default = _default;
});