define("monitor-ui/routes/app/projects/list", ["exports", "monitor-ui/utils/session-manager"], function (_exports, _sessionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // export default class AppProjectsRoute extends Route {
  var _default = Ember.Route.extend({
    model() {
      return new Ember.RSVP.hash({
        projects: this.store.findAll('project'),
        isCrossTenant: _sessionManager.default.getCurrentTenantId() === undefined // userPermissions:this.sessionContext.currentUser.getUserProjectPermissions()

      });
    }

  });

  _exports.default = _default;
});