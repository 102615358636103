define("monitor-ui/router", ["exports", "monitor-ui/config/environment", "monitor-ui/utils/session-manager"], function (_exports, _environment, _sessionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const Router = Ember.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.rootURL,
    metrics: Ember.inject.service(),
    sessionContext: Ember.inject.service(),
    tenantGuardedRoutes: _environment.default['active-tenant-guard'].guardedRoutes,
    tenantSwitchAttempted: false,

    didTransition() {
      this._super(...arguments);

      this._trackPage();
    },

    willTransition(oldInfos, newInfos, transition) {
      this._super(...arguments);

      const targetUrl = _sessionManager.default.computeUrlFromTransition(transition);

      if (this.get('tenantGuardedRoutes').includes(transition.targetName)) {
        const transitionToken = transition.queryParams.tcbToken || _sessionManager.default.getAccessToken();

        const tokenPayload = _sessionManager.SessionManager.getTokenPayload(transitionToken);

        if (!tokenPayload.active_tenant_id) {
          this.transitionTo('app.projects');
          return;
        }

        return;
      }

      if (!this.tenantSwitchAttempted) {
        this.tenantSwitchAttempted = true;
        transition.abort();

        this._checkForTenantSwitching(targetUrl).then(() => transition.retry()).finally(() => {
          Ember.run.next(this, () => {
            this.tenantSwitchAttempted = false;
          });
        });
      }
    },

    _trackPage() {
      Ember.run.scheduleOnce('afterRender', this, () => {
        const page = this.url;
        const title = this.getWithDefault('currentRouteName', 'unknown');
        const user = this.sessionContext.currentUser;

        if (user.get('trackingConsent')) {
          this.metrics.trackPage({
            page,
            title
          });
        }
      });
    },

    async _checkForTenantSwitching(targetUrl) {
      if (!_sessionManager.default.getAccessToken()) return Promise.resolve();
      const currentTenantID = await _sessionManager.default.getCurrentTenantId();
      const {
        searchParams
      } = new URL(window.location);

      if (!searchParams.get('tenantID') && targetUrl) {
        return _sessionManager.default.switchToCrossTenants(targetUrl);
      }

      if (currentTenantID && searchParams.get('tenantID') !== currentTenantID.toString() && targetUrl) {
        return _sessionManager.default.switchTenant(Number(searchParams.get('tenantID')), targetUrl);
      }

      return Promise.resolve();
    }

  });
  Router.map(function () {
    this.route('index', {
      path: '/'
    });
    this.route('login');
    this.route('otp-login');
    this.route('recover');
    this.route('error');
    this.route('app', function () {
      this.route('projects', function () {
        this.route('project', {
          path: '/:project_id'
        }, function () {
          this.route('reports', function () {
            this.route('report', {
              path: '/:report_id'
            });
          });
          this.route('evaluations', function () {
            this.route('evaluation', {
              path: '/:evaluation_session_id'
            });
            this.route('evaluation-details', {
              path: '/:evaluation_session_id/details'
            });
            this.route('list');
            this.route('evaluation-results', {
              path: '/:evaluation_session_id/results'
            });
          });
          this.route('surveys');
          this.route('sessions');
          this.route('edit');
        });
        this.route('list');
        this.route('new');
      });
      this.route('account', function () {
        this.route('password');
        this.route('profile');
      }); // this.route('dashboard');

      this.route('surveys', function () {
        this.route('survey', {
          path: '/:survey_id'
        }, function () {
          this.route('questions', {
            path: 'questions'
          });
        });
        this.route('list');
      });
      this.route('organisation', function () {
        this.route('dimensions');
      });
      this.route('organisation-dashboard');
    });
    this.route('new_password');
  });
  var _default = Router;
  _exports.default = _default;
});