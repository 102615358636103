define("monitor-ui/templates/components/question-report/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "bnXSwLma",
    "block": "{\"symbols\":[\"answer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ml-3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"overflow-auto overflow-max-height\"],[9],[0,\"\\n    \"],[7,\"ul\"],[11,\"class\",\"list-group list-group-flush\"],[9],[0,\"\\n\"],[4,\"each\",[[23,[\"question\",\"answers\"]]],null,{\"statements\":[[0,\"        \"],[7,\"li\"],[11,\"class\",\"list-group-item text-dark \"],[9],[0,\"\\n          \"],[1,[22,1,[]],false],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-report/input.hbs"
    }
  });

  _exports.default = _default;
});