define("monitor-ui/routes/app/projects/project/index", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectIndexRoute extends Ember.Route {
    model() {
      const projectId = this.modelFor('app.projects.project').project.id;
      return this.store.query('evaluation-session', {
        project_id: projectId,
        active: true
      });
    }

    setupController(controller, model) {
      const routeModel = this.modelFor('app.projects.project');
      controller.set('project', routeModel.project);
      controller.set('projectPerms', routeModel.projectReportPermissions);
      controller.set('openEvaluationSessions', model);
      controller.set('currentUser', routeModel.currentUser);
    }

  }

  _exports.default = AppProjectsProjectIndexRoute;
});