define("monitor-ui/routes/app/account/password", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/service"], function (_exports, _decorate2, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppAccountPasswordRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute) {
    class AppAccountPasswordRoute extends _EmberRoute {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppAccountPasswordRoute,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changePassword",
        value: function changePassword(passwordData) {
          if (passwordData.password && passwordData.password !== passwordData.retypePassword) {
            this.notify.error('New password does not match ');
          } else {
            const user = this.modelFor('app.account');
            user.setProperties({
              'currentPassword': passwordData.currentPassword,
              'password': passwordData.password,
              'retypePassword': passwordData.retypePassword
            });
            user.save({
              adapterOptions: {
                change_password: true
              }
            }).then(() => {
              this.transitionTo('app.projects.list');
              this.notify.success('Password changed');
            }).catch(err => {
              if ('errors' in err) {
                err.errors.forEach(e => {
                  this.notify.error(e.detail);
                });
              } else {
                this.notify.error('An error occured');
              }
            });
          }
        }
      }]
    };
  }, Ember.Route);
  _exports.default = AppAccountPasswordRoute;
});