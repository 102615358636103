define("monitor-ui/templates/components/task-input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "rqUs44a1",
    "block": "{\"symbols\":[\"@placeholder\",\"@value\"],\"statements\":[[1,[27,\"input\",null,[[\"class\",\"value\",\"key-press\",\"focus-out\",\"placeholder\"],[\"task-input\",[22,2,[]],[27,\"perform\",[[22,0,[\"changeTask\"]]],null],[27,\"perform\",[[22,0,[\"changeTask\"]]],null],[22,1,[]]]]],false],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/task-input.hbs"
    }
  });

  _exports.default = _default;
});