define("monitor-ui/utils/login", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.refreshAndBlacklistCall = _exports.refreshCall = _exports.jwtPayload = void 0;

  const jwtPayload = token => {
    if (token) {
      return JSON.parse(atob(token.split('.')[1]));
    }

    return null;
  };

  _exports.jwtPayload = jwtPayload;

  const refreshCall = async (refreshToken, tenantId = undefined) => {
    try {
      const response = await fetch('/api/jwt/refresh/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh: refreshToken,
          tenant: tenantId
        })
      });
      const data = await response.json();
      return {
        accessToken: data.access
      };
    } catch (error) {
      window.SessionManager.logout();
      const {
        response
      } = token;

      if (response.status === 401) {
        window.location.assign('/login');
      } else if (window.location.search.includes('?token=')) {
        window.location.replace(window.location.href.split('?')[0]);
      } else {
        window.location.reload();
      }
    }
  };

  _exports.refreshCall = refreshCall;

  const refreshAndBlacklistCall = async (refreshToken, tenantId = undefined) => {
    try {
      const response = await fetch('/api/jwt/refresh-and-blacklist/', {
        method: 'POST',
        headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          refresh: refreshToken,
          tenant: tenantId
        })
      });
      const data = await response.json();
      return {
        accessToken: data.access,
        refreshToken: data.refresh
      };
    } catch (error) {
      window.SessionManager.logout();
      const {
        response
      } = token;

      if (response.status === 401) {
        window.location.assign('/login');
      } else if (window.location.search.includes('?token=')) {
        window.location.replace(window.location.href.split('?')[0]);
      } else {
        window.location.reload();
      }
    }
  };

  _exports.refreshAndBlacklistCall = refreshAndBlacklistCall;
});