define("monitor-ui/routes/app/projects/new", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsNewRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute) {
    class AppProjectsNewRoute extends _EmberRoute {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsNewRoute,
      d: [{
        kind: "method",
        key: "model",
        value: function model() {
          return Ember.RSVP.hash({
            projectStandards: this.store.findAll('service-standard'),
            industryStandards: this.store.findAll('industry-standard'),
            companyQuotes: this.store.findAll('quote'),
            company: this.sessionContext.currentUser.get('company')
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "willTransition",
        value: function willTransition() {
          if (this.controller.project.hasDirtyAttributes) {
            this.controller.project.rollbackAttributes();
          }
        }
      }, {
        kind: "method",
        key: "setupController",
        value: function setupController(controller, model) {
          const project = this.store.createRecord('project', {
            company: model.company,
            title: null,
            published: null,
            businessUnit: null,
            initialSituation: null,
            plannedApproach: null,
            expectedResults: null,
            projectManager: null,
            status: false
          });
          controller.set('user', this.sessionContext.currentUser);
          controller.set('projects', model.companyQuotes.toArray());
          controller.set('selectedApaduaProject', null);
          controller.set('projectStandards', model.projectStandards.toArray());
          controller.set('industryStandards', model.industryStandards.toArray());
          controller.set('project', project);
          controller.setupChangeset();
        }
      }]
    };
  }, Ember.Route);
  _exports.default = AppProjectsNewRoute;
});