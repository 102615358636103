define("monitor-ui/templates/components/question-report/likert-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "i8AMpEhs",
    "block": "{\"symbols\":[\"choice\",\"index\",\"@question\"],\"statements\":[[7,\"div\"],[11,\"class\",\"ml-3\"],[9],[0,\"\\n\"],[4,\"each\",[[22,3,[\"choices\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[11,\"class\",\"container choice-box-report my-3\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"row\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-6 col-sm-12\"],[9],[0,\"\\n          \"],[7,\"span\"],[11,\"class\",\"badge badge-box p-1 mr-2 \"],[9],[1,[27,\"index-to-char\",[[22,2,[]]],null],false],[10],[0,\"\\n          \"],[1,[22,1,[\"fixed_text\"]],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"text\"]]],null,{\"statements\":[[0,\"            ,\\n\"]],\"parameters\":[]},null],[0,\"          \"],[1,[22,1,[\"text\"]],false],[0,\"\\n        \"],[10],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"col-md-6 col-sm-12 text-right\"],[9],[0,\"\\n\"],[4,\"if\",[[22,1,[\"user_count\"]]],null,{\"statements\":[[0,\"            \"],[1,[22,1,[\"user_count\"]],false],[0,\" responses\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"            0 responses\\n\"]],\"parameters\":[]}],[0,\"\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"progress m-1 progress-bg-color text-dark\"],[9],[0,\"\\n        \"],[7,\"div\"],[11,\"class\",\"progress-bar\"],[11,\"role\",\"progressbar\"],[12,\"style\",[28,[\"width: \",[22,1,[\"percentage\"]],\"%;\"]]],[12,\"aria-valuenow\",[28,[[22,1,[\"percentage\"]]]]],[11,\"aria-valuemin\",\"0\"],[11,\"aria-valuemax\",\"100\"],[9],[1,[22,1,[\"percentage\"]],false],[0,\"%\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-report/likert-scale.hbs"
    }
  });

  _exports.default = _default;
});