define("monitor-ui/routes/login", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _decorate2, _service, _object, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LoginRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class LoginRoute extends _EmberRoute$extend {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: LoginRoute,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "intl",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "login",
        value: function login(loginData) {
          if (this.theme.companySettings) {
            loginData.company = this.theme.companySettings.id;
          }

          return this.session.authenticate('authenticator:token', loginData).then(() => {}, response => {
            const data = response.responseJSON;

            if (data.otp_required_token) {
              this.transitionTo('otp-login', {
                queryParams: {
                  otp_required_token: data.otp_required_token
                }
              });
            } else {
              alert('User or password incorrect!');
            }
          }).catch(() => {
            alert('User or password incorrect!');
          });
        }
      }]
    };
  }, Ember.Route.extend(_unauthenticatedRouteMixin.default));
  _exports.default = LoginRoute;
});