define("monitor-ui/controllers/app/projects/project/reports", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectReportsController extends Ember.Controller {}

  _exports.default = AppProjectsProjectReportsController;
});