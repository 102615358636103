define("monitor-ui/routes/app/surveys", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppSurveyRoute extends Ember.Route {}

  _exports.default = AppSurveyRoute;
});