define("monitor-ui/components/question-editor-meta", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let SelectedQuestionComponent = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class SelectedQuestionComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: SelectedQuestionComponent,
      d: [{
        kind: "get",
        key: "getInitialDimension",
        value: function getInitialDimension() {
          if (this.question && this.question.indicator && this.question.indicator.get('dimension')) {
            return this.question.indicator.get('dimension');
          }

          return this.dimensions.get('firstObject');
        }
      }, {
        kind: "field",
        key: "selectedDimension",

        value() {
          return null;
        }

      }, {
        kind: "method",
        decorators: [(0, _object.on)('didReceiveAttrs')],
        key: "initialSetup",
        value: function initialSetup() {
          this.set('selectedDimension', this.getInitialDimension);
        }
      }, {
        kind: "method",
        decorators: [(0, _object.observes)('selectedDimension')],
        key: "changeIndicatorDimension",
        value: function changeIndicatorDimension() {
          if (this.question && this.question.indicator && this.question.indicator.get('dimension') !== this.selectedDimension) {
            Ember.set(this.question, 'indicator', null);
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeQuestionIndicator",
        value: function changeQuestionIndicator(indicator) {
          Ember.set(this.question, 'indicator', indicator);
          this.question.send('becomeDirty');

          if (this.saveQuestion) {
            this.saveQuestion(this.question);
          }
        }
      }, {
        kind: "method",
        decorators: [(0, _object.observes)('question.isRequired')],
        key: "saveQuestionParameters",
        value: function saveQuestionParameters() {
          if (this.saveQuestion) {
            this.saveQuestion(this.question);
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = SelectedQuestionComponent;
});