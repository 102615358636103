define("monitor-ui/routes/app/projects/project/evaluations/evaluation-results", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectEvaluationsEvaluationResultsRoute extends Ember.Route {
    model(params, transition) {
      const canViewReports = this.modelFor('app.projects.project').projectReportPermissions.length > 0;

      if (canViewReports) {
        return Ember.RSVP.hash({
          evaluationSession: this.store.find('evaluation-session', params.evaluation_session_id)
        }).then(model => {
          return Ember.RSVP.hash({
            evaluationSession: model.evaluationSession,
            surveyAnswerStats: model.evaluationSession.surveyAnswerStats()
          });
        });
      }

      return null;
    }

    setupController(controller, model) {
      controller.set('evaluationSession', model.evaluationSession);
      controller.set('surveyAnswerStats', model.surveyAnswerStats.data);
    }

  }

  _exports.default = AppProjectsProjectEvaluationsEvaluationResultsRoute;
});