define("monitor-ui/controllers/app/projects/list", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object/computed", "@ember-decorators/object"], function (_exports, _decorate2, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsListController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsListController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsListController,
      d: [{
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsListController;
});