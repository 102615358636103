define("monitor-ui/components/dimensions-manager", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/service", "ember-concurrency-decorators", "@ember-decorators/component", "@ember-decorators/object/computed"], function (_exports, _decorate2, _object, _service, _emberConcurrencyDecorators, _component, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let DimensionsManagerComponent = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class DimensionsManagerComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: DimensionsManagerComponent,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "sessionContext",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }, {
        kind: "method",
        decorators: [(0, _object.on)('init')],
        key: "initialSetup",
        value: function initialSetup() {
          this.set('activeDimension', null);
          this.set('isEditing', false);
          this.set('activeIndicator', null);
          this.store.query('dimension', {}).then(data => {
            this.set('dimensions', data.toArray());
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleEditPhase",
        value: function toggleEditPhase() {
          this.toggleProperty('isEditing');
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeSelectedDimension",
        value: function changeSelectedDimension(dimension) {
          if (this.activeDimension && this.activeDimension.hasDirtyAttributes) {
            if (confirm('Do you want to save the changes?')) {
              if (!this.validateItem(this.activeDimension)) {
                this.notify.error('The name fields may not be blank');

                if (this.activeDimension.get('isNew')) {
                  this.deleteDimensionFromTheList(this.activeDimension);
                }

                this.activeDimension.rollbackAttributes();
              } else this.submitDimension.perform(this.activeDimension);
            } else {
              if (this.activeDimension.get('isNew')) {
                this.deleteDimensionFromTheList(this.activeDimension);
              }

              this.activeDimension.rollbackAttributes();
            }
          }

          this.checkIfActiveIndicatorIsUnsaved();
          this.set('activeDimension', dimension);
          this.set('activeIndicator', false);
          this.set('isEditing', false);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeActiveIndicator",
        value: function changeActiveIndicator(indicator) {
          this.checkIfActiveIndicatorIsUnsaved();
          this.set('activeIndicator', indicator);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "checkIfActiveIndicatorIsUnsaved",
        value: function checkIfActiveIndicatorIsUnsaved() {
          if (this.activeIndicator && this.activeIndicator.hasDirtyAttributes) {
            if (confirm('Do you want to save the changes?')) {
              if (!this.validateItem(this.activeIndicator)) {
                this.notify.error('The name fields may not be blank');
                this.activeIndicator.rollbackAttributes();
              } else this.submitIndicator.perform(this.activeIndicator);
            } else {
              this.activeIndicator.rollbackAttributes();
            }
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "deleteDimensionFromTheList",
        value: function deleteDimensionFromTheList(dimension) {
          this.dimensions.removeObject(dimension);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "cancelForm",
        value: function cancelForm(dimension) {
          if (dimension.get('isNew')) {
            this.deleteDimensionFromTheList(dimension);
            this.set('activeDimension', null);
          }

          dimension.rollbackAttributes();
          this.set('isEditing', false);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "validateItem",
        value: function validateItem(item) {
          if (item.name && item.name_de) {
            item.set('name', item.name.trim());
            item.set('name_de', item.name_de.trim());
          }

          return !(Ember.isEmpty(item.name) || Ember.isEmpty(item.name_de));
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "submitDimension",
        value: function* submitDimension(item) {
          if (!this.validateItem(item)) {
            this.notify.error('The name fields may not be blank');
          } else {
            try {
              yield item.save();
              this.notify.success('Saved successfully');
              this.set('isEditing', false);
            } catch (e) {
              this.notify.error('Dimension could not be saved');
            }
          }
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "submitIndicator",
        value: function* submitIndicator(item) {
          if (!this.validateItem(item)) {
            this.notify.error('The name fields may not be blank');
          } else {
            try {
              yield item.save();
              this.notify.success('Saved successfully');
              this.set('activeIndicator', false);
            } catch (e) {
              this.notify.error('Indicator could not be saved');
            }
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "createDimension",
        value: function createDimension() {
          let dimension = this.store.createRecord('dimension', {
            name: null,
            name_de: null,
            required: true,
            company: this.user.get('company')
          });
          this.dimensions.pushObject(dimension);
          this.set('activeDimension', dimension);
          this.set('isEditing', true);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "createIndicator",
        value: function createIndicator(dimension) {
          const indicator = this.store.createRecord('indicator', {
            name: null,
            name_de: null,
            dimension: dimension
          });
          this.set('activeIndicator', indicator);
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "deleteDimensionOrIndicator",
        value: function* deleteDimensionOrIndicator(item, type) {
          var hasConfirmed = false;

          try {
            if (confirm('Are you sure you want to destroy this record?')) {
              yield item.destroyRecord();
              hasConfirmed = true;
            }
          } catch (e) {
            this.notify.error('Record could not be deleted');
          } finally {
            if (type !== 'indicator' && hasConfirmed) {
              this.set('activeDimension', null);
              this.reloadDimensions.perform();
            }
          }
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "reloadDimensions",
        value: function* reloadDimensions() {
          try {
            let data = yield this.store.query('dimension', {});
            this.set('dimensions', data.toArray());
          } catch (e) {
            this.notify.error('Error reloading dimensions');
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "isCompanyNotNull",
        value: function isCompanyNotNull(company) {
          return !Ember.isEmpty(company.get('content'));
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "isCompanyNull",
        value: function isCompanyNull(company) {
          return Ember.isEmpty(company.get('content'));
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "closeSelectedDimension",
        value: function closeSelectedDimension() {
          this.set('activeDimension', null);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "closeSelectedIndicator",
        value: function closeSelectedIndicator(indicator) {
          indicator.rollbackAttributes();
          this.set('activeIndicator', null);
        }
      }]
    };
  }, Ember.Component);
  _exports.default = DimensionsManagerComponent;
});