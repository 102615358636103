define("monitor-ui/models/user", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    email: attr('string'),
    firstName: attr('string'),
    lastName: attr('string'),
    password: attr('string'),
    language: attr('string', {
      defaultValue: 'en'
    }),
    trackingConsent: attr('boolean', {
      defaultValue: null
    }),
    currentPassword: attr('string'),
    retypePassword: attr('string'),
    jobTitle: attr('string'),
    phone: attr('string'),
    mobilePhone: attr('string'),
    fax: attr('string'),
    isClient: attr('boolean', {
      defaultValue: true
    }),
    isConsultant: attr('boolean', {
      defaultValue: false
    }),
    isActive: attr('boolean'),
    isStaff: attr('boolean'),
    isCompanyAdmin: attr('boolean'),
    isMonitorAdmin: attr('boolean'),
    emailValidated: attr('boolean', {
      defaultValue: false
    }),
    tcbValidated: attr('boolean', {
      defaultValue: false
    }),
    profileCompleted: attr('boolean', {
      defaultValue: false
    }),
    token: attr('string'),
    company: belongsTo('company', {
      async: true,
      inverse: 'companyUsers'
    }),
    // Company Info Fields
    newCompany: attr('boolean', {
      defaultValue: false
    }),
    companyName: attr('string'),
    'addressLine-1': _emberData.default.attr('string'),
    'addressLine-2': _emberData.default.attr('string'),
    'addressLine-3': _emberData.default.attr('string'),
    postalCode: attr('string'),
    city: attr('string'),
    state: attr('string'),
    // country: belongsTo('country', {
    //   async: true,
    // }),
    registry: attr('string'),
    registryNumber: attr('string'),
    vatNumber: attr('string'),
    dunsId: attr('number'),
    website: attr('string'),
    newsletterSubscription: attr('boolean', {
      defaultValue: true
    }),
    resendEvaluationInvitation: (0, _emberApiActions.memberAction)({
      path: 'resend-evaluation-invitation/',
      type: 'post'
    }),
    deleteEvaluationInvitation: (0, _emberApiActions.memberAction)({
      path: 'delete-evaluation-invitation/',
      type: 'post'
    }),
    getUserProjectPermissions: (0, _emberApiActions.memberAction)({
      path: 'project-user-report-permissions/',
      type: 'get'
    }),
    createCompanyUser: (0, _emberApiActions.memberAction)({
      path: 'create-company-user/',
      type: 'post'
    }),
    fullName: Ember.computed('firstName', 'lastName', function () {
      return this.firstName + ' ' + this.lastName;
    }),
    surveys: hasMany('surveys', {
      inverse: 'user'
    }),
    surveyAnswers: hasMany('survey-answer', {
      inverse: 'user'
    }) // countryObserver: observer('country', function () {
    //   if (this.get('country')) {
    //     this.get('errors').remove('country');
    //   }
    // }),

  });

  _exports.default = _default;
});