define("monitor-ui/authorizers/token", ["exports", "ember-simple-auth/authorizers/base", "monitor-ui/utils/session-manager", "monitor-ui/utils/login"], function (_exports, _base, _sessionManager, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    session: Ember.inject.service(),

    async authorize(data, block) {
      const token = _sessionManager.default.getAccessToken();

      const expire = (0, _login.jwtPayload)(token).exp;
      const now = new Date().getTime() / 1000;

      if (expire - now < 30) {
        const refreshToken = _sessionManager.default.getRefreshToken();

        if (refreshToken) {
          const token = await (0, _login.refreshCall)(refreshToken);

          if (token.accessToken) {
            _sessionManager.default.setAccessToken(token.accessToken);

            block('Authorization', `Bearer ${token.accessToken}`);
          } else {
            _sessionManager.default.logout();
          }
        } else {
          _sessionManager.default.logout();
        }
      } else {
        block('Authorization', `Bearer ${token}`);
      }
    }

  });

  _exports.default = _default;
});