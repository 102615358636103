define("monitor-ui/controllers/app/projects/project/index", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectIndexController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsProjectIndexController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectIndexController,
      d: [{
        kind: "get",
        decorators: [(0, _object.computed)('projectPerms', 'currentUser.isMonitorAdmin')],
        key: "isAdmin",
        value: function isAdmin() {
          return !!this.projectPerms.findBy('role', 'admin');
        }
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('projectPerms', 'currentUser')],
        key: "isReport",
        value: function isReport() {
          return !!this.projectPerms.findBy('role', 'report');
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsProjectIndexController;
});