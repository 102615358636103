define("monitor-ui/routes/app/projects/project/surveys", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectSurveysRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute) {
    class AppProjectsProjectSurveysRoute extends _EmberRoute {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectSurveysRoute,
      d: [{
        kind: "method",
        key: "model",
        value: function model() {
          const projectId = this.modelFor('app.projects.project').project.id;
          return this.store.query('survey', {
            project_id: projectId
          });
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "reloadModel",
        value: function reloadModel() {
          this.refresh();
        }
      }, {
        kind: "method",
        key: "setupController",
        value: function setupController(controller, model) {
          const routeModel = this.modelFor('app.projects.project');
          controller.set('project', routeModel.project);
          controller.set('projectPerms', routeModel.projectReportPermissions);
          controller.set('currentUser', routeModel.currentUser);
          controller.set('surveys', model);
        }
      }]
    };
  }, Ember.Route);
  _exports.default = AppProjectsProjectSurveysRoute;
});