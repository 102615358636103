define("monitor-ui/templates/components/question/statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "t1q7pkIA",
    "block": "{\"symbols\":[\"@save\"],\"statements\":[[0,\"\\n\"],[5,\"task-input\",[],[[\"@placeholder\",\"@value\",\"@change\"],[\"Message text \",[23,[\"question\",\"text\"]],[27,\"action\",[[22,0,[]],[22,1,[]],[23,[\"question\"]]],null]]]],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question/statement.hbs"
    }
  });

  _exports.default = _default;
});