define("monitor-ui/routes/new-password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class NewPasswordRoute extends Ember.Route {}

  _exports.default = NewPasswordRoute;
});