define("monitor-ui/routes/app/projects/reports/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsReportsReportRoute extends Ember.Route {}

  _exports.default = AppProjectsReportsReportRoute;
});