define("monitor-ui/controllers/app/surveys/survey", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "ember-concurrency-decorators", "ember-concurrency", "@ember-decorators/service"], function (_exports, _decorate2, _object, _emberConcurrencyDecorators, _emberConcurrency, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppSurveysSurveyController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppSurveysSurveyController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppSurveysSurveyController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        key: "showSavingSpinner",

        value() {
          return false;
        }

      }, {
        kind: "field",
        key: "activeQuestion",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "questionForDelete",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "activeDimension",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "activeIndicator",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "isDimensionManagerActive",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "surveyTypes",

        value() {
          return ['ongoing', 'conclusion', 'sustainability'];
        }

      }, {
        kind: "method",
        decorators: [(0, _object.observes)('model.survey.surveyType')],
        key: "surveyTypeObserver",
        value: function surveyTypeObserver() {
          this.saveItem.perform(this.model.survey);
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "waitOneSecondForSpinner",
        value: function* waitOneSecondForSpinner() {
          yield (0, _emberConcurrency.timeout)(1000);
          Ember.set(this, 'showSavingSpinner', true);
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "saveItem",
        value: function* saveItem(item) {
          let oneSecondWaitForSpinner = this.waitOneSecondForSpinner.perform();

          try {
            if (item.hasDirtyAttributes) {
              yield item.save();
            }
          } catch (e) {
            e.errors.forEach(error => {
              if (error && error.detail) {
                this.notify.error(error.detail);
              }
            });
          } finally {
            oneSecondWaitForSpinner.cancel();
            Ember.set(this, 'showSavingSpinner', false);
          }
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "createDimension",
        value: function* createDimension() {
          try {
            const dimension = this.store.createRecord('dimension', {
              name: 'your_en_name',
              name_de: 'deine_DE_name',
              required: true
            });
            yield this.saveDimensionOrIndicator.perform(dimension);
            Ember.set(this, 'activeDimension', dimension);

            if (this.activeIndicator) {
              this.closeSelectedIndicator();
            }
          } catch (e) {}
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "createIndicator",
        value: function* createIndicator(dimension) {
          try {
            const indicator = this.store.createRecord('indicator', {
              name: 'indicator_default_en',
              name_de: 'indicator_default_de',
              dimension: dimension
            });
            yield this.saveDimensionOrIndicator.perform(indicator);
            Ember.set(this, 'activeIndicator', indicator);
          } catch (e) {}
        }
      }, {
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "reloadDimensions",
        value: function* reloadDimensions() {
          try {
            Ember.set(this, 'isDimensionManagerActive', null);
            Ember.set(this.model, 'dimensions', this.store.query('dimension', {
              project_id: this.model.projectID
            }));
          } catch (e) {}
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "updateQuestion",
        value: function updateQuestion(question) {
          this.saveItem.perform(question);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "updateChoice",
        value: function updateChoice(choice) {
          this.saveItem.perform(choice);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeSelectedDimension",
        value: function changeSelectedDimension(dimension) {
          Ember.set(this, 'activeDimension', dimension);
          this.closeSelectedIndicator();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeSelectedIndicator",
        value: function changeSelectedIndicator(indicator) {
          Ember.set(this, 'activeIndicator', indicator);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "closeSelectedIndicator",
        value: function closeSelectedIndicator() {
          Ember.set(this, 'activeIndicator', null);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeSelectedQuestion",
        value: function changeSelectedQuestion(question) {
          Ember.set(this, 'activeQuestion', question);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "closeActiveQuestion",
        value: function closeActiveQuestion() {
          Ember.set(this, 'activeQuestion', null);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "closeSelectedDimension",
        value: function closeSelectedDimension() {
          if (this.activeIndicator) {
            this.closeSelectedIndicator();
          }

          Ember.set(this, 'activeDimension', null);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "addLikertQuestion",
        value: function addLikertQuestion(previousQuestion) {
          let before_question_id = this.setBeforeQuestion(previousQuestion);
          const question = this.store.createRecord('surveyQuestion', {
            is_required: true,
            description: 'default',
            type: 'likert-scale',
            text: '',
            before_question_id: before_question_id,
            survey: this.model.survey
          });
          this.saveCreatedQuestion(question);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "addInputQuestion",
        value: function addInputQuestion(previousQuestion) {
          let before_question_id = this.setBeforeQuestion(previousQuestion);
          const question = this.store.createRecord('surveyQuestion', {
            is_required: false,
            description: 'default',
            type: 'input',
            text: '',
            before_question_id: before_question_id,
            survey: this.model.survey
          });
          this.saveCreatedQuestion(question);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "addStatementQuestion",
        value: function addStatementQuestion(previousQuestion) {
          let before_question_id = this.setBeforeQuestion(previousQuestion);
          const question = this.store.createRecord('surveyQuestion', {
            is_required: false,
            description: 'default',
            type: 'statement',
            text: '',
            before_question_id: before_question_id,
            survey: this.model.survey
          });
          this.saveCreatedQuestion(question);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "setBeforeQuestion",
        value: function setBeforeQuestion(previousQuestion) {
          let before_question_id = null;

          if (previousQuestion) {
            before_question_id = previousQuestion.id;
          }

          return before_question_id;
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "animateOnEditableQuestion",
        value: function* animateOnEditableQuestion(editableQuestion) {
          if (editableQuestion) {
            $('.viewer').animate({
              scrollTop: $('#question-' + editableQuestion.id).offset().top - 100
            });
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "saveCreatedQuestion",
        value: function saveCreatedQuestion(question) {
          question.save().then(() => {}).catch(err => {}).finally(() => {
            this.reloadSurvey.perform();
          });
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "deleteQuestion",
        value: function* deleteQuestion() {
          try {
            if (this.questionForDelete === this.activeQuestion) {
              Ember.set(this, 'activeQuestion', null);
            }

            yield this.questionForDelete.destroyRecord();
            this.notify.success('deleted');
          } catch (e) {
            this.notify.error('Item could not be deleted');
          } finally {
            this.reloadSurvey.perform();
            this.set('questionForDelete', null);
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "saveQuestion",
        value: function saveQuestion(question) {
          if (question.hasDirtyAttributes) {
            question.save();
          }
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "reloadSurvey",
        value: function* reloadSurvey() {
          try {
            let previousQuestions = this.model.survey.hasMany('surveyQuestions').value();
            const survey = yield this.store.findRecord('survey', this.model.survey.id, {
              include: 'questions.choices'
            });
            let newQuestions = survey.hasMany('surveyQuestions').value();
            previousQuestions.forEach(item => {
              if (!newQuestions.findBy('id', item.id)) {
                this.store.unloadRecord(item);
              }
            });
          } catch (e) {}
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppSurveysSurveyController;
});