define("monitor-ui/templates/app/projects/project/reports/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "GZT31osk",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"display: flex; flex-direction: column;\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"style\",\"display: flex; flex-direction: column; align-items: center;\"],[9],[0,\"\\n\"],[4,\"link-to\",[\"app.projects.project.reports\"],null,{\"statements\":[[0,\"    \"],[7,\"button\"],[11,\"class\",\"btn btn-outline-primary m-1\"],[11,\"style\",\"width: 180px\"],[9],[0,\"\\n      View All Reports\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\\n\"],[4,\"if\",[[23,[\"report\"]]],null,{\"statements\":[[0,\"    \"],[7,\"div\"],[12,\"style\",[27,\"if\",[[27,\"or\",[[23,[\"media\",\"isDesktop\"]],[23,[\"media\",\"isJumbo\"]]],null],\"margin-left: 50px; margin-right: 50px;\"],null]],[9],[0,\"\\n      \"],[1,[23,[\"report\",\"embeeded\"]],true],[0,\"\\n    \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/app/projects/project/reports/report.hbs"
    }
  });

  _exports.default = _default;
});