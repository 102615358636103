define("monitor-ui/models/dashboard-configuration", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    vendorManagementDashboard: attr(),
    projectManagementDashboard: attr(),
    company: belongsTo('company', {
      inverse: 'dashboardConfigurations'
    })
  });

  _exports.default = _default;
});