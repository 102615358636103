define("monitor-ui/models/user-evaluation-survey", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    user: belongsTo('user'),
    evaluationSession: belongsTo('evaluation-session'),
    status: attr(),
    evalUuid: attr(),
    userUuid: attr(),
    dateCreated: attr('date'),
    lastUpdated: attr('date'),
    dateSubmitted: attr('date'),
    surveyAnswers: hasMany('survey-answer', {
      inverse: 'userEvaluationSurvey'
    })
  });

  _exports.default = _default;
});