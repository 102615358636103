define("monitor-ui/components/indicator-manager", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class IndicatorManagerComponent extends Ember.Component {}

  _exports.default = IndicatorManagerComponent;
});