define("monitor-ui/templates/components/question-report-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "D1z5i0we",
    "block": "{\"symbols\":[\"@question\"],\"statements\":[[7,\"div\"],[11,\"class\",\"card m-2  card-style\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"card-body\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"row  ml-1 question-text-style text-primary\"],[9],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"w-15\"],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"question-order-badge \"],[9],[1,[22,1,[\"order\"]],false],[0,\". \"],[10],[0,\"\\n      \"],[10],[0,\"\\n      \"],[7,\"div\"],[11,\"class\",\"ml-1 w-75\"],[9],[0,\"\\n        \"],[1,[22,1,[\"text\"]],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"isRequired\"]]],null,{\"statements\":[[0,\"          *\\n\"]],\"parameters\":[]},null],[0,\"      \"],[10],[0,\"\\n    \"],[10],[0,\"\\n\"],[4,\"unless\",[[27,\"eq\",[[22,1,[\"type\"]],\"statement\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"text-secondary ml-4  under-title-text\"],[9],[1,[22,1,[\"responses\"]],false],[0,\" out of \"],[1,[22,1,[\"users_invited\"]],false],[0,\"\\n        people answered this\\n        question\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[1,[27,\"component\",[[27,\"concat\",[\"question-report/\",[23,[\"question\",\"type\"]]],null]],[[\"question\"],[[23,[\"question\"]]]]],false],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-report-view.hbs"
    }
  });

  _exports.default = _default;
});