define("monitor-ui/routes/app/organisation-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppOrganisationDashboardRoute extends Ember.Route {
    model() {
      return this.store.findAll('dashboard-configuration');
    }

    setupController(controller, model) {
      controller.set('configuration', model.firstObject);
    }

  }

  _exports.default = AppOrganisationDashboardRoute;
});