define("monitor-ui/routes/app/surveys/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppSurveysListRoute extends Ember.Route {
    model() {
      return this.store.findAll('survey');
    }

  }

  _exports.default = AppSurveysListRoute;
});