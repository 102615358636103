define("monitor-ui/validations/project", ["exports", "ember-changeset-validations/validators"], function (_exports, _validators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = {
    title: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    projectManager: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    businessUnit: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    industryStandard: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    serviceStandard: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    startDate: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })],
    endDate: [(0, _validators.validatePresence)({
      presence: true,
      allowBlank: false
    })]
  };
  _exports.default = _default;
});