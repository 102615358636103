define("monitor-ui/authenticators/token", ["exports", "ember-simple-auth/authenticators/base", "monitor-ui/config/environment", "jquery", "monitor-ui/utils/session-manager", "monitor-ui/utils/login"], function (_exports, _base, _environment, _jquery, _sessionManager, _login) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = _base.default.extend({
    otpTokenEndpoint: _environment.default.backendAPI + _environment.default['simple-token'].otpTokenEndpoint,
    serverTokenEndpoint: _environment.default.backendAPI + _environment.default['simple-token'].serverTokenEndpoint,
    verifyServerTokenEndpoint: _environment.default.backendAPI + _environment.default['simple-token'].verifyServerTokenEndpoint,
    tokenAttributeName: 'token',
    identificationAttributeName: _environment.default['simple-token'].identificationAttributeName,

    otpAuthenticate(credentials) {
      return new Ember.RSVP.Promise((resolve, reject) => {
        _jquery.default.ajax({
          url: this.otpTokenEndpoint,
          type: 'POST',
          headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json',
            'Authorization': 'Bearer null'
          },
          data: JSON.stringify(credentials),
          dataType: 'json'
        }).then((data, statusText, response) => {
          if (response.status !== 200) {
            reject(response);
          }

          _sessionManager.default.setRefreshToken(data.refresh);

          if (data.otp_required_token) {
            reject(response);
          }

          const decodedAccess = (0, _login.jwtPayload)(data.access);

          _sessionManager.default.setAccessToken(data.access);

          data.user_id = decodedAccess.user_id;
          data.token = data.access;
          data.email = decodedAccess.email;
          resolve(response);
        }, response => {
          reject(response);
        });
      });
    },

    authenticate(credentials) {
      credentials.username = credentials.email;

      if (credentials.otp_required_token) {
        return this.otpAuthenticate(credentials);
      }

      if (credentials.sessionToken) {
        return new Promise((resolve, reject) => {
          const decoded = (0, _login.jwtPayload)(credentials.sessionToken);

          const currentToken = _sessionManager.default.getAccessToken();

          let currentDecoded = {
            user_id: null
          };

          if (currentToken) {
            currentDecoded = (0, _login.jwtPayload)(currentToken);
          }

          let refreshed = {
            access: null
          };

          if (currentDecoded.user_id !== decoded.user_id) {
            if (decoded.token_type === 'refresh') {
              refreshed = (0, _login.refreshAndBlacklistCall)(credentials.sessionToken);
            }

            if (refreshed.access || decoded.token_type === 'access') {
              if (decoded.token_type === 'refresh') {
                _sessionManager.default.setRefreshToken(refreshed.refresh);
              }

              _sessionManager.default.setAccessToken(refreshed.access);

              credentials.token = credentials.sessionToken;

              _jquery.default.ajax({
                url: this.verifyServerTokenEndpoint,
                type: 'POST',
                headers: {
                  'Accept': 'application/json',
                  'Content-Type': 'application/json'
                },
                data: JSON.stringify(credentials),
                dataType: 'json'
              }).then((data, statusText, response) => {
                if (response.status !== 200) {
                  reject(response);
                }

                resolve(response);
              }, response => {
                reject(response);
              });
            } else {
              reject();
            }
          } else {
            credentials.token = credentials.sessionToken;

            _jquery.default.ajax({
              url: this.verifyServerTokenEndpoint,
              type: 'POST',
              headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
              },
              data: JSON.stringify(credentials),
              dataType: 'json'
            }).then((data, statusText, response) => {
              if (response.status !== 200) {
                reject(response);
              }

              resolve(response);
            }, response => {
              reject(response);
            });
          }
        });
      } else {
        return new Ember.RSVP.Promise((resolve, reject) => {
          _jquery.default.ajax({
            url: this.serverTokenEndpoint,
            type: 'POST',
            headers: {
              'Accept': 'application/json',
              'Content-Type': 'application/json'
            },
            data: JSON.stringify(credentials),
            dataType: 'json'
          }).then((data, statusText, response) => {
            if (response.status !== 200) {
              reject(response);
            }

            _sessionManager.default.setRefreshToken(data.refresh);

            if (data.otp_required_token) {
              reject(response);
            }

            _sessionManager.default.setAccessToken(data.access);

            const decodedAccess = (0, _login.jwtPayload)(data.access);
            data.user_id = decodedAccess.user_id;
            data.token = data.access;
            data.email = decodedAccess.email;
            resolve(response);
          }, response => {
            reject(response);
          });
        });
      }
    },

    restore(data) {
      const {
        tokenAttributeName,
        identificationAttributeName
      } = this;
      const tokenAttribute = Ember.get(data, tokenAttributeName);
      const identificationAttribute = Ember.get(data, identificationAttributeName);
      return new Ember.RSVP.Promise((resolve, reject) => {
        if (!Ember.isEmpty(tokenAttribute) && !Ember.isEmpty(identificationAttribute)) {
          resolve(data);
        } else {
          reject();
        }
      });
    },

    invalidate() {
      return Ember.RSVP.resolve();
    }

  });

  _exports.default = _default;
});