define("monitor-ui/templates/components/question/likert-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "70uT6l/F",
    "block": "{\"symbols\":[\"choice\",\"@save\",\"@question\"],\"statements\":[[7,\"div\"],[11,\"class\",\"likert-scale\"],[9],[0,\"\\n  \"],[5,\"task-input\",[],[[\"@placeholder\",\"@value\",\"@change\"],[\"Likert statement..\",[23,[\"question\",\"text\"]],[27,\"action\",[[22,0,[]],[22,2,[]],[23,[\"question\"]]],null]]]],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"liker-scale-list\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"sort-by\",[\"order\",[22,3,[\"questionChoices\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"liker-scale-option d-flex\"],[9],[0,\"\\n        \"],[7,\"div\"],[9],[0,\"\\n          - \"],[1,[22,1,[\"fixedText\"]],false],[4,\"if\",[[22,1,[\"text\"]]],null,{\"statements\":[[0,\",\"]],\"parameters\":[]},null],[0,\"\\n        \"],[10],[0,\"\\n\\n        \"],[5,\"task-input\",[[11,\"class\",\"flex-grow-1\"]],[[\"@placeholder\",\"@value\",\"@change\"],[\"additional text..\",[22,1,[\"text\"]],[27,\"action\",[[22,0,[]],[22,2,[]],[22,1,[]]],null]]]],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[1]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question/likert-scale.hbs"
    }
  });

  _exports.default = _default;
});