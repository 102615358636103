define("monitor-ui/helpers/index-to-char", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.indexToChar = indexToChar;
  _exports.default = void 0;

  function indexToChar(params
  /*, hash*/
  ) {
    return String.fromCharCode(65 + params[0]);
  }

  var _default = Ember.Helper.helper(indexToChar);

  _exports.default = _default;
});