define("monitor-ui/components/indicator-form", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component", "@ember-decorators/object", "@ember-decorators/service"], function (_exports, _decorate2, _component, _object, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let IndicatorFormComponent = (0, _decorate2.default)([(0, _component.tagName)('')], function (_initialize, _EmberComponent) {
    class IndicatorFormComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: IndicatorFormComponent,
      d: []
    };
  }, Ember.Component);
  _exports.default = IndicatorFormComponent;
});