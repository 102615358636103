define("monitor-ui/services/theme", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object/computed", "@ember-decorators/object"], function (_exports, _decorate2, _service, _computed, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let ThemeService = (0, _decorate2.default)(null, function (_initialize, _EmberService) {
    class ThemeService extends _EmberService {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: ThemeService,
      d: [{
        kind: "field",
        decorators: [(0, _service.inject)('head-data')],
        key: "headData",
        value: void 0
      }, {
        kind: "field",
        key: "companySettings",

        value() {
          return null;
        }

      }, {
        kind: "field",
        decorators: [(0, _computed.alias)('companySettings.logo')],
        key: "logo",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('companySettings.navType')],
        key: "navType",
        value: function navType() {
          if (this.companySettings && this.companySettings.navType) {
            return this.companySettings.navType;
          }

          return 'dark';
        }
      }, {
        kind: "method",
        key: "setCompanySettings",
        value: function setCompanySettings(settings) {
          this.companySettings = settings;
          this.headData.companySettings = settings; //TODO: set favicon
        }
      }]
    };
  }, Ember.Service);
  _exports.default = ThemeService;
});