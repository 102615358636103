define("monitor-ui/controllers/app/projects/project/edit", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object", "@ember-decorators/service", "monitor-ui/validations/project", "ember-changeset-validations", "ember-changeset", "ember-concurrency-decorators", "moment", "@ember-decorators/object/computed", "@ember-decorators/controller"], function (_exports, _decorate2, _object, _service, _project, _emberChangesetValidations, _emberChangeset, _emberConcurrencyDecorators, _moment, _computed, _controller) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppProjectsProjectEditController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppProjectsProjectEditController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppProjectsProjectEditController,
      d: [{
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        key: "selectedApaduaProject",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "isEditting",

        value() {
          return false;
        }

      }, {
        kind: "method",
        key: "setupChangeset",
        value: // @controller  AppProjectsProject
        function setupChangeset() {
          Ember.set(this, 'projectChangeset', new _emberChangeset.default(this.project, (0, _emberChangesetValidations.default)(_project.default), _project.default));
          Ember.set(this.projectChangeset, 'startDate', (0, _moment.default)(this.projectChangeset.get('startDate')).utc().format('YYYY-MM-DD'));
          Ember.set(this.projectChangeset, 'endDate', (0, _moment.default)(this.projectChangeset.get('endDate')).utc().format('YYYY-MM-DD'));
        }
      }, {
        kind: "method",
        decorators: [(0, _emberConcurrencyDecorators.task)({
          restartable: true
        })],
        key: "submitAction",
        value: function* submitAction(changeset) {
          let oldStartDate = changeset.get('startDate');
          let oldEndDate = changeset.get('endDate');

          try {
            changeset.set('startDate', (0, _moment.default)(changeset.get('startDate')).format('YYYY-MM-DD 00:mm:ss'));
            changeset.set('endDate', (0, _moment.default)(changeset.get('endDate')).format('YYYY-MM-DD 23:59:59'));
            yield changeset.save();
            this.toggleIsEditting();
            this.setupChangeset();
          } catch (e) {
            changeset.set('startDate', oldStartDate);
            changeset.set('endDate', oldEndDate);

            if (e.errors) {
              e.errors.forEach(error => {
                if (error.detail) this.notify.error(error.detail);
              });
            }
          }
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "cancelForm",
        value: function cancelForm() {
          this.projectChangeset.rollback();
          this.setupChangeset();
          this.toggleIsEditting();
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "toggleIsEditting",
        value: function toggleIsEditting() {
          this.toggleProperty('isEditting');
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeApaduaProject",
        value: function changeApaduaProject() {
          if (this.selectedApaduaProject.startDate) Ember.set(this.projectChangeset, 'startDate', (0, _moment.default)(this.selectedApaduaProject.startDate).utc().format('YYYY-MM-DD'));
          if (this.selectedApaduaProject.endDate) Ember.set(this.projectChangeset, 'endDate', (0, _moment.default)(this.selectedApaduaProject.endDate).utc().format('YYYY-MM-DD'));
          Ember.set(this.projectChangeset, 'title', this.selectedApaduaProject.projectName);
          Ember.set(this.projectChangeset, 'initialSituation', this.selectedApaduaProject.initialSituation);
          Ember.set(this.projectChangeset, 'industryStandard', this.selectedApaduaProject.industryStandard);
          Ember.set(this.projectChangeset, 'serviceStandard', this.selectedApaduaProject.serviceStandard);
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppProjectsProjectEditController;
});