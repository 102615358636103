define("monitor-ui/controllers/new-password", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "monitor-ui/config/environment", "ember-ajax/errors"], function (_exports, _decorate2, _service, _object, _environment, _errors) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let NewPasswordController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class NewPasswordController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: NewPasswordController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "ajax",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        key: "queryParams",

        value() {
          return ['recoverToken'];
        }

      }, {
        kind: "field",
        key: "recoverToken",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "passwordData",

        value() {
          return Ember.Object.extend({
            password: null,
            retypePassword: null
          }).create();
        }

      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changePassword",
        value: function changePassword(passwordData) {
          const recoverToken = this.recoverToken;
          const ajax = this.get('ajax');

          if (!recoverToken) {
            this.notify.error('Please check your email and use the provided link to recover your password.');
          } else {
            const newPassword = passwordData.password;
            const retypePassword = passwordData.retypePassword;

            if (newPassword === retypePassword) {
              ajax.post(_environment.default.backendAPI + '/api/change-password/', {
                data: {
                  token: recoverToken,
                  new_password: newPassword,
                  retype_password: retypePassword
                }
              }).then(() => {
                passwordData = Ember.Object.extend({
                  password: null,
                  retypePassword: null
                }).create();
                this.set('passwordData', passwordData);
                this.transitionToRoute('login');
                this.get('notify').success('Password was updated successfully.');
              }).catch(response => {
                if (response instanceof _errors.ForbiddenError) {
                  this.get('notify').error('Your email link expired.');
                } else {
                  if ('payload' in response) {
                    response.payload.forEach(e => {
                      this.notify.error(e.detail);
                    });
                  } else {
                    this.get('notify').error('An error has occurred.');
                  }
                }
              });
            } else {
              this.get('notify').error('The entered passwords do not match. Please enter the same value.');
            }
          }
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = NewPasswordController;
});