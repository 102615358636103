define("monitor-ui/components/question-report/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QuestionReportInputComponent extends Ember.Component {}

  _exports.default = QuestionReportInputComponent;
});