define("monitor-ui/routes/app/projects/project/evaluations/list", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectEvaluationsListRoute extends Ember.Route {}

  _exports.default = AppProjectsProjectEvaluationsListRoute;
});