define("monitor-ui/models/survey-question", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    isRequired: attr(),
    description: attr(),
    type: attr(),
    text: attr(),
    order: attr(),
    survey: belongsTo('survey', {
      inverse: 'surveyQuestions'
    }),
    questionChoices: hasMany('question-choice', {
      inverse: 'surveyQuestion'
    }),
    surveyAnswers: hasMany('survey-answer', {
      inverse: 'surveyQuestion'
    }),
    indicator: belongsTo('indicator', {
      inverse: 'surveyQuestions'
    }),
    before_question_id: attr()
  });

  _exports.default = _default;
});