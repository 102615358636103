define("monitor-ui/models/project-report-permission", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-data", "@ember-decorators/data"], function (_exports, _decorate2, _emberData, _data) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;
  let ProjectReportPermissionModel = (0, _decorate2.default)(null, function (_initialize, _Model) {
    class ProjectReportPermissionModel extends _Model {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: ProjectReportPermissionModel,
      d: [{
        kind: "field",
        decorators: [(0, _data.belongsTo)('user')],
        key: "user",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.belongsTo)('project')],
        key: "project",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _data.attr)('string')],
        key: "role",
        value: void 0
      }]
    };
  }, Model);
  _exports.default = ProjectReportPermissionModel;
});