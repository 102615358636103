define("monitor-ui/templates/components/footer", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "kKovzMGW",
    "block": "{\"symbols\":[\"&default\"],\"statements\":[[7,\"div\"],[11,\"class\",\"text-center text-muted p-3\"],[9],[0,\"\\n  \"],[14,1],[0,\"\\n  Powered by \"],[7,\"a\"],[11,\"href\",\"https://apadua.com\"],[11,\"class\",\"text-muted\"],[11,\"target\",\"_blank\"],[9],[0,\"Apadua\"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/footer.hbs"
    }
  });

  _exports.default = _default;
});