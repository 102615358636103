define("monitor-ui/components/question-answers/statement", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _decorate2, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let StatementComponent = (0, _decorate2.default)([(0, _component.tagName)('div')], function (_initialize, _EmberComponent) {
    class StatementComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: StatementComponent,
      d: [{
        kind: "get",
        decorators: [(0, _object.computed)('question', 'activeQuestion')],
        key: "isActive",
        value: function isActive() {
          return this.question === this.activeQuestion;
        }
      }, {
        kind: "method",
        decorators: [(0, _object.observes)('question', 'activeQuestion', 'component_state')],
        key: "setFocus",
        value: function setFocus() {
          if (this.question === this.activeQuestion) {
            const inputHtml = this.element.getElementsByClassName('statementbtn')[0];

            if (inputHtml) {
              inputHtml.focus();
            }
          }
        }
      }, {
        kind: "method",
        key: "click",
        value: function click(e) {}
      }]
    };
  }, Ember.Component);
  _exports.default = StatementComponent;
});