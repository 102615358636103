define("monitor-ui/helpers/is-after-current-date", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.isAfterCurrentDate = isAfterCurrentDate;
  _exports.default = void 0;

  function isAfterCurrentDate(params
  /*, hash*/
  ) {
    // console.log('--------------------')
    // console.log('new Date = ', new Date())
    // console.log('params[0] =', params[0])
    // console.log('--------------------')
    if (params[0] > new Date()) return true;
    return false;
  }

  var _default = Ember.Helper.helper(isAfterCurrentDate);

  _exports.default = _default;
});