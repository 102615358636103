define("monitor-ui/components/question-answers/input", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _decorate2, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let QuestionAnswersInputComponent = (0, _decorate2.default)([(0, _component.tagName)('div')], function (_initialize, _EmberComponent) {
    class QuestionAnswersInputComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: QuestionAnswersInputComponent,
      d: [{
        kind: "method",
        decorators: [(0, _object.observes)('question', 'activeQuestion')],
        key: "setFocus",
        value: function setFocus() {
          if (this.question === this.activeQuestion) {
            const inputHtml = this.element.getElementsByClassName('answer-input')[0];

            if (inputHtml) {
              inputHtml.focus();
            }
          }
        }
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('questionAnswers', 'question')],
        key: "currentAnswer",
        value: function currentAnswer() {
          if (this.questionAnswers) {
            return this.questionAnswers.findBy('surveyQuestion.id', this.question.id);
          }
        } //didRecevieAttribute or didInsertElement

      }, {
        kind: "method",
        key: "didReceiveAttrs",
        value: function didReceiveAttrs() {
          if (this.currentAnswer) this.set('value', this.currentAnswer.text);
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "keyPressAction",
        value: function keyPressAction(e) {
          if (e.keyCode === 13 && this.sendAnswer) {
            this.sendAnswer(this.question, this.value, true);
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = QuestionAnswersInputComponent;
});