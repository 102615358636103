define("monitor-ui/models/company", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    hasMany
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    companyName: _emberData.default.attr('string'),
    companyNameAlias: _emberData.default.attr('string'),
    published: _emberData.default.attr('boolean'),
    isConsultant: _emberData.default.attr('boolean'),
    isClient: _emberData.default.attr('boolean'),
    indicators: hasMany('indicator', {
      inverse: 'company'
    }),
    dimensions: hasMany('dimension', {
      inverse: 'company'
    }),
    projects: hasMany('project', {
      inverse: 'company'
    }),
    dashboardConfigurations: hasMany('dashboardConfiguration', {
      inverse: 'company'
    }),
    extraConsultant: _emberData.default.attr('boolean', {
      defaultValue: false
    }),
    companyUsers: _emberData.default.hasMany('user', {
      async: true,
      inverse: 'company'
    }),
    logo: _emberData.default.attr('string')
  });

  _exports.default = _default;
});