define("monitor-ui/models/evaluation-session", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    group: belongsTo('group'),
    groupName: attr('string'),
    project: belongsTo('project'),
    survey: belongsTo('survey'),
    startDate: attr('date'),
    endDate: attr('date'),
    externalSurveyUrl: attr('string'),
    externalSurveyReportUrl: attr('string'),
    startUserEvaluation: (0, _emberApiActions.memberAction)({
      path: 'start-user-evaluation/',
      type: 'post'
    }),
    submitUserEvaluation: (0, _emberApiActions.memberAction)({
      path: 'submit-user-evaluation/',
      type: 'post'
    }),
    surveyAnswerStats: (0, _emberApiActions.memberAction)({
      path: 'survey-answers-stats/',
      type: 'get'
    })
  });

  _exports.default = _default;
});