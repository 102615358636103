define("monitor-ui/templates/components/question-answers/statement", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "6vl9RPaw",
    "block": "{\"symbols\":[\"@question\",\"@sendAnswer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row ml-3\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col-3\"],[9],[0,\"\\n    \"],[7,\"button\"],[11,\"class\",\"statementbtn btn btn-primary p-2 m-1 mt-3 ml-2\"],[9],[0,\"\\n      Continue\\n    \"],[3,\"action\",[[22,0,[]],[27,\"optional\",[[22,2,[]]],null],[22,1,[]],[22,1,[\"text\"]],true],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"],[4,\"if\",[[27,\"media\",[\"isDesktop\"],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[11,\"class\",\"input-app-info-text text-primary d-none d-sm-block\"],[9],[0,\"\\n        press\\n        \"],[7,\"strong\"],[9],[0,\"\\n          ENTER\\n        \"],[10],[0,\"\\n      \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-answers/statement.hbs"
    }
  });

  _exports.default = _default;
});