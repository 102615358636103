define("monitor-ui/services/session-context", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Service.extend({
    session: Ember.inject.service('session'),
    store: Ember.inject.service(),
    currentUser: Ember.computed('session.data.authenticated.user_id', function () {
      const accountId = this.get('session.data.authenticated.user_id');

      if (!Ember.isEmpty(accountId)) {
        return _emberData.default.PromiseObject.create({
          promise: this.store.find('user', accountId)
        });
      }
    })
  });

  _exports.default = _default;
});