define("monitor-ui/templates/app/organisation-dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "j6j0WyoR",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"style\",\"display: flex; flex-direction: column;\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"configuration\"]]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[12,\"style\",[27,\"if\",[[27,\"or\",[[23,[\"media\",\"isDesktop\"]],[23,[\"media\",\"isJumbo\"]]],null],\"margin-left: 50px; margin-right: 50px;\"],null]],[9],[0,\"\\n            \"],[1,[23,[\"configuration\",\"vendorManagementDashboard\"]],true],[0,\"\\n            \"],[1,[23,[\"configuration\",\"projectManagementDashboard\"]],true],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/app/organisation-dashboard.hbs"
    }
  });

  _exports.default = _default;
});