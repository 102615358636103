define("monitor-ui/models/project", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    projectId: attr(),
    title: attr(),
    businessUnit: attr(),
    initialSituation: attr(),
    plannedApproach: attr(),
    expectedResults: attr(),
    projectManager: attr(),
    status: attr(),
    startDate: attr(),
    endDate: attr(),
    company: belongsTo('company', {
      inverse: 'projects'
    }),
    industryStandard: belongsTo('industry-standard', {
      async: true
    }),
    serviceStandard: belongsTo('service-standard', {
      async: true
    }),
    projectReports: hasMany('project-report', {
      async: true
    }),
    surveys: hasMany('survey', {
      inverse: 'project'
    })
  });

  _exports.default = _default;
});