define("monitor-ui/routes/app/projects/project", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectRoute extends Ember.Route {
    model(params) {
      return new Ember.RSVP.hash({
        project: this.store.find('project', params.project_id),
        projectReportPermissions: this.store.query('project-report-permission', {
          project_id: params.project_id
        }),
        currentUser: this.sessionContext.currentUser
      });
    }

    setupController(controller, model) {
      controller.set('project', model.project);
      controller.set('projectPerms', model.projectReportPermissions);
      controller.set('currentUser', model.currentUser);
      super.setupController(controller, model);
    }

  }

  _exports.default = AppProjectsProjectRoute;
});