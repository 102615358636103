define("monitor-ui/routes/otp-login", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object", "ember-simple-auth/mixins/unauthenticated-route-mixin"], function (_exports, _decorate2, _service, _object, _unauthenticatedRouteMixin) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let OtpLoginRoute = (0, _decorate2.default)(null, function (_initialize, _EmberRoute$extend) {
    class OtpLoginRoute extends _EmberRoute$extend {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: OtpLoginRoute,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "notify",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "intl",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }]
    };
  }, Ember.Route.extend(_unauthenticatedRouteMixin.default));
  _exports.default = OtpLoginRoute;
});