define("monitor-ui/routes/app/surveys/survey", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppSurveysSurveyRoute extends Ember.Route {
    model(params) {
      return new Ember.RSVP.hash({
        survey: this.store.findRecord('survey', params.survey_id, {
          include: 'questions.choices'
        }),
        currentUser: this.sessionContext.currentUser
      }).then(model => {
        let projectID = model.survey.belongsTo('project').id();
        return Ember.RSVP.hash({
          survey: model.survey,
          dimensions: this.store.query('dimension', {
            project_id: projectID
          }),
          currentUser: model.currentUser,
          projectID: projectID
        });
      });
    }

    setupController(controller, context, _transition) {
      controller.set('activeQuestion', null);
      controller.set('activeDimension', null);
      controller.set('activeIndicator', null);
      controller.set('isDimensionManagerActive', null);
      controller.set('questionForDelete', null);
      super.setupController(controller, context, _transition);
    }

  }

  _exports.default = AppSurveysSurveyRoute;
});