define("monitor-ui/templates/components/question-answers/likert-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "RFqDjaLW",
    "block": "{\"symbols\":[\"choice\",\"index\",\"@question\",\"@sendAnswer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row m-1   p-2 \"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col ml-3 likert-scale-box\"],[11,\"tabindex\",\"0\"],[9],[0,\"\\n\"],[4,\"each\",[[27,\"sort-by\",[\"order\",[22,3,[\"questionChoices\"]]],null]],null,{\"statements\":[[0,\"      \"],[7,\"div\"],[12,\"class\",[28,[\"container  \",[27,\"if\",[[27,\"eq\",[[22,1,[\"id\"]],[23,[\"currentAnswer\",\"questionChoice\",\"id\"]]],null],\"current-selected-box\"],null],\" choice-box m-1 p-3 \"]]],[9],[0,\"\\n        \"],[7,\"span\"],[11,\"class\",\"badge badge-box p-1 mr-2 \"],[9],[1,[27,\"index-to-char\",[[22,2,[]]],null],false],[10],[0,\"\\n        \"],[1,[22,1,[\"fixedText\"]],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"text\"]]],null,{\"statements\":[[0,\"          ,\\n\"]],\"parameters\":[]},null],[0,\"        \"],[1,[22,1,[\"text\"]],false],[0,\"\\n      \"],[3,\"action\",[[22,0,[]],[27,\"optional\",[[22,4,[]]],null],[22,3,[]],[22,1,[]],true],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"]],\"parameters\":[1,2]},null],[0,\"  \"],[10],[0,\"\\n\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-answers/likert-scale.hbs"
    }
  });

  _exports.default = _default;
});