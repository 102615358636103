define("monitor-ui/models/survey", ["exports", "ember-data", "ember-api-actions"], function (_exports, _emberData, _emberApiActions) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    hasMany,
    belongsTo
  } = _emberData.default;

  var _default = Model.extend({
    title: attr(),
    description: attr(),
    welcomeMessage: attr(),
    template: attr(),
    language: attr(),
    isDraft: attr(),
    isTemplate: attr(),
    dateCreated: attr(),
    surveyQuestions: hasMany('survey-question', {
      inverse: 'survey'
    }),
    user: belongsTo('user', {
      inverse: 'surveys'
    }),
    redirectUrl: attr(),
    surveyType: attr(),
    project: belongsTo('project', {
      inverser: 'surveys'
    }),
    evaluationSessions: hasMany('evaluation-session', {
      inverse: 'survey'
    }),
    cloneSurvey: (0, _emberApiActions.memberAction)({
      path: 'clone/',
      type: 'post'
    })
  });

  _exports.default = _default;
});