define("monitor-ui/components/survey-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class SurveyViewComponent extends Ember.Component {}

  _exports.default = SurveyViewComponent;
});