define("monitor-ui/components/question-editor", ["exports", "@ember-decorators/component", "@ember-decorators/object"], function (_exports, _component, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  // @tagName('')
  class QuestionEditorComponent extends Ember.Component {
    constructor(...args) {
      super(...args);
      this.flag = false;
    }

  }

  _exports.default = QuestionEditorComponent;
});