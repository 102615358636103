define("monitor-ui/helpers/exists-in-array", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.existsInArray = existsInArray;
  _exports.default = void 0;

  function existsInArray(params
  /*, hash*/
  ) {
    return params[0].includes(Number(params[1]));
  }

  var _default = Ember.Helper.helper(existsInArray);

  _exports.default = _default;
});