define("monitor-ui/components/task-textarea", ["exports", "@babel/runtime/helpers/esm/decorate", "ember-concurrency-decorators", "ember-concurrency", "@ember-decorators/component"], function (_exports, _decorate2, _emberConcurrencyDecorators, _emberConcurrency, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let TaksTextareaComponent = (0, _decorate2.default)([(0, _component.tagName)('div')], function (_initialize, _EmberComponent) {
    class TaksTextareaComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: TaksTextareaComponent,
      d: [{
        kind: "method",
        decorators: [_emberConcurrencyDecorators.restartableTask],
        key: "changeTask",
        value: function* changeTask() {
          yield (0, _emberConcurrency.timeout)(1000);

          if (this.attrs.change) {
            this.attrs.change();
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = TaksTextareaComponent;
});