define("monitor-ui/components/dimension-form", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class DimensionFormComponent extends Ember.Component {}

  _exports.default = DimensionFormComponent;
});