define("monitor-ui/controllers/login", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let LoginController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class LoginController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: LoginController,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        key: "loginData",

        value() {
          return Ember.Object.extend({
            email: null,
            password: null
          }).create();
        }

      }]
    };
  }, Ember.Controller);
  _exports.default = LoginController;
});