define("monitor-ui/controllers/app/organisation", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object/computed"], function (_exports, _decorate2, _computed) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let AppOrganisationController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppOrganisationController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppOrganisationController,
      d: [{
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }]
    };
  }, Ember.Controller);
  _exports.default = AppOrganisationController;
});