define("monitor-ui/routes/app/projects/project/reports/report", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppProjectsProjectReportsReportRoute extends Ember.Route {
    model(params, transition) {
      if (params.report_id == 'lastEmbedded') {
        return this.store.query('project-report', {
          'project_id': transition.params['app.projects.project'].project_id,
          'last_embedded': 'True'
        }).then(obj => {
          return obj.firstObject;
        }).catch(() => {
          this.transitionTo('app.projects.project.reports');
        });
      } else {
        return this.store.find('project-report', params.report_id);
      }
    }

    setupController(controller, model) {
      controller.set('report', model);
    }

  }

  _exports.default = AppProjectsProjectReportsReportRoute;
});