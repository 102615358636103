define("monitor-ui/models/question-choice", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    text: attr(),
    fixedText: attr(),
    value: attr(),
    order: attr(),
    surveyQuestion: belongsTo('survey-question', {
      inverse: 'questionChoices'
    }),
    surveyAnswers: hasMany('survey-answer', {
      inverse: 'questionChoice'
    })
  });

  _exports.default = _default;
});