define("monitor-ui/templates/app/projects/new", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "95omdH5R",
    "block": "{\"symbols\":[],\"statements\":[[7,\"div\"],[11,\"class\",\"container\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"p-2\"],[9],[10],[0,\"\\n\\n  \"],[5,\"project-form\",[],[[\"@changeset\",\"@user\",\"@submitAction\",\"@projects\",\"@selectedApaduaProject\",\"@projectStandards\",\"@industryStandards\",\"@changeApaduaProject\",\"@cancelForm\"],[[22,0,[\"projectChangeset\"]],[22,0,[\"user\"]],[27,\"perform\",[[22,0,[\"submitAction\"]]],null],[22,0,[\"projects\"]],[22,0,[\"selectedApaduaProject\"]],[22,0,[\"projectStandards\"]],[22,0,[\"industryStandards\"]],[27,\"action\",[[22,0,[]],\"changeApaduaProject\"],null],[27,\"action\",[[22,0,[]],\"cancelForm\"],null]]],{\"statements\":[],\"parameters\":[]}],[0,\"\\n\\n\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/app/projects/new.hbs"
    }
  });

  _exports.default = _default;
});