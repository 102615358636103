define("monitor-ui/models/project-report", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    belongsTo,
    attr
  } = _emberData.default;

  var _default = Model.extend({
    project: belongsTo('project'),
    file: attr('string'),
    name: attr('string'),
    date: attr('date'),
    embeeded: attr('string')
  });

  _exports.default = _default;
});