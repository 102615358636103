define("monitor-ui/templates/components/question-view", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "u+4lC8l1",
    "block": "{\"symbols\":[\"@question\",\"&default\"],\"statements\":[[7,\"div\"],[12,\"id\",[28,[\"question-\",[22,1,[\"id\"]]]]],[12,\"class\",[28,[\"container m-1 p-1 question-box \",[27,\"if\",[[23,[\"isActive\"]],\"active-box\",\"inactive-box\"],null],\" \",[27,\"if\",[[23,[\"isRequired\"]],\"incompleted-box\"],null],\" \"]]],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"row m-1 p-1 question-text-style text-primary\"],[9],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"w-15\"],[9],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"question-order-badge \"],[9],[1,[22,1,[\"order\"]],false],[0,\"  \"],[10],[0,\"\\n      \"],[7,\"span\"],[11,\"class\",\"question-order-badge \"],[9],[7,\"i\"],[11,\"class\",\"fa fa-arrow-right\"],[11,\"aria-hidden\",\"true\"],[9],[10],[10],[0,\"\\n    \"],[10],[0,\"\\n    \"],[7,\"div\"],[11,\"class\",\"ml-1 w-75\"],[9],[0,\"\\n      \"],[1,[22,1,[\"text\"]],false],[0,\"\\n\"],[4,\"if\",[[22,1,[\"isRequired\"]]],null,{\"statements\":[[0,\"        *\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n  \"],[14,2],[0,\"\\n\"],[3,\"action\",[[22,0,[]],\"focusTrap\"]],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-view.hbs"
    }
  });

  _exports.default = _default;
});