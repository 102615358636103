define("monitor-ui/routes/app/surveys/survey/questions", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppSurveysSurveyQuestionsRoute extends Ember.Route {
    model(params) {
      return new Ember.RSVP.hash({
        survey: this.store.findRecord('survey-question.js', params.survey_id),
        currentUser: this.sessionContext.currentUser
      });
    }

  }

  _exports.default = AppSurveysSurveyQuestionsRoute;
});