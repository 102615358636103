define("monitor-ui/components/question-report/likert-scale", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class QuestionReportLikertScaleComponent extends Ember.Component {}

  _exports.default = QuestionReportLikertScaleComponent;
});