define("monitor-ui/templates/components/question-answers/input", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "iMGy78bj",
    "block": "{\"symbols\":[\"@question\",\"@sendAnswer\"],\"statements\":[[7,\"div\"],[11,\"class\",\"row m-1  my-3 p-2\"],[9],[0,\"\\n  \"],[7,\"div\"],[11,\"class\",\"col ml-2\"],[9],[0,\"\\n    \"],[1,[27,\"input\",null,[[\"class\",\"value\",\"placeholder\",\"keyPress\",\"autofocus\",\"focus-out\"],[\"answer-input text-primary\",[22,0,[\"value\"]],\"Type your answer here...\",[27,\"action\",[[22,0,[]],[27,\"optional\",[[23,[\"keyPressAction\"]]],null]],null],[27,\"if\",[[27,\"eq\",[[23,[\"canFocus\"]],true],null],\"autofocus\"],null],[27,\"action\",[[22,0,[]],[27,\"optional\",[[22,2,[]]],null],[22,1,[]],[22,0,[\"value\"]],false],null]]]],false],[0,\"\\n    \"],[7,\"div\"],[9],[0,\"\\n      \"],[7,\"button\"],[11,\"class\",\"btn btn-primary p-2 m-1 mt-3\"],[9],[0,\"\\n        OK\\n      \"],[3,\"action\",[[22,0,[]],[27,\"optional\",[[22,2,[]]],null],[22,1,[]],[22,0,[\"value\"]],true],[[\"bubbles\"],[false]]],[10],[0,\"\\n\"],[4,\"if\",[[27,\"media\",[\"isDesktop\"],null]],null,{\"statements\":[[0,\"        \"],[7,\"div\"],[11,\"class\",\"input-app-info-text text-primary d-none d-sm-block\"],[9],[0,\"\\n          press\\n          \"],[7,\"strong\"],[9],[0,\"\\n            ENTER\\n          \"],[10],[0,\"\\n        \"],[10],[0,\"\\n\"]],\"parameters\":[]},null],[0,\"    \"],[10],[0,\"\\n  \"],[10],[0,\"\\n\"],[10],[0,\"\\n\"]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/components/question-answers/input.hbs"
    }
  });

  _exports.default = _default;
});