define("monitor-ui/models/survey-answer-stats", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model
  } = _emberData.default;

  class SurveyAnswerStatsModel extends Model {}

  _exports.default = SurveyAnswerStatsModel;
});