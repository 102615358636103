define("monitor-ui/components/question-view", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/object"], function (_exports, _decorate2, _object) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let QuestionViewComponent = (0, _decorate2.default)(null, function (_initialize, _EmberComponent) {
    class QuestionViewComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: QuestionViewComponent,
      d: [{
        kind: "get",
        decorators: [(0, _object.computed)('question.id', 'remainingQuestions')],
        key: "isRequired",
        value: function isRequired() {
          if (this.remainingQuestions) {
            return this.remainingQuestions.includes(Number(this.question.id));
          }

          return false;
        }
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('question', 'activeQuestion')],
        key: "isActive",
        value: function isActive() {
          if (this.question === this.activeQuestion && this.animateOnEditableQuestion) {
            this.animateOnEditableQuestion(this.activeQuestion);
          }

          return this.question === this.activeQuestion;
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "focusTrap",
        value: function focusTrap() {
          if (!this.isActive && this.setCurrentQuestionBox) {
            this.setCurrentQuestionBox(this.question);
          }
        }
      }]
    };
  }, Ember.Component);
  _exports.default = QuestionViewComponent;
});