define("monitor-ui/routes/app/account", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppAccountRoute extends Ember.Route {
    model() {
      return this.get('sessionContext.currentUser');
    }

  }

  _exports.default = AppAccountRoute;
});