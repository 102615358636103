define("monitor-ui/models/quote-monitor-lite", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    attr
  } = _emberData.default;

  var _default = _emberData.default.Model.extend({
    projectName: attr('string'),
    initialSituation: attr(),
    startDate: attr(),
    endDate: attr(),
    industryStandard: _emberData.default.belongsTo('industry-standard', {
      async: true
    }),
    serviceStandard: _emberData.default.belongsTo('service-standard', {
      async: true
    })
  });

  _exports.default = _default;
});