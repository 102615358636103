define("monitor-ui/controllers/app", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service", "@ember-decorators/object/computed", "@ember-decorators/object", "jquery", "monitor-ui/config/environment", "monitor-ui/utils/session-manager"], function (_exports, _decorate2, _service, _computed, _object, _jquery, _environment, _sessionManager) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const CROSS_TENANTS_ID = 'cross-tenants';
  let AppController = (0, _decorate2.default)(null, function (_initialize, _EmberController) {
    class AppController extends _EmberController {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: AppController,
      d: [{
        kind: "field",
        key: "tenantsEndpoint",

        value() {
          return _environment.default['tenants'].tenantsEndpoint;
        }

      }, {
        kind: "field",
        key: "tenantGuardedRoutes",

        value() {
          return _environment.default['active-tenant-guard'].guardedRoutes;
        }

      }, {
        kind: "field",
        key: "collapsed",

        value() {
          return true;
        }

      }, {
        kind: "field",
        key: "tenantLogoError",

        value() {
          return null;
        }

      }, {
        kind: "field",
        key: "tenants",

        value() {
          return [];
        }

      }, {
        kind: "field",
        key: "selectedTenant",

        value() {
          return {
            id: CROSS_TENANTS_ID,
            name: 'All Tenants',
            company_name: null,
            logo: null
          };
        }

      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "theme",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "router",
        value: void 0
      }, {
        kind: "field",
        decorators: [(0, _computed.alias)('sessionContext.currentUser')],
        key: "user",
        value: void 0
      }, {
        kind: "get",
        decorators: [(0, _object.computed)('router.currentRouteName')],
        key: "routeIsGuarded",
        value: function routeIsGuarded() {
          return this.tenantGuardedRoutes.includes(this.router.currentRouteName);
        }
      }, {
        kind: "method",
        decorators: [(0, _object.on)('init')],
        key: "tenantNameObserver",
        value: function tenantNameObserver() {
          _jquery.default.ajax({
            url: this.tenantsEndpoint,
            type: 'GET',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: `Bearer ${_sessionManager.default.getAccessToken()}`
            }
          }).then((data, statusText, response) => {
            let alltenants = data;
            alltenants.push({
              id: CROSS_TENANTS_ID,
              name: 'All Tenants',
              company_name: null,
              logo: null
            });
            Ember.set(this, 'tenants', alltenants);

            const currentTenantId = _sessionManager.default.getCurrentTenantId();

            if (currentTenantId) {
              const tenant = data.find(tenant => tenant.id === currentTenantId);

              if (tenant) {
                Ember.set(this, 'selectedTenant', tenant);
              }
            }
          }, () => {});
        }
      }, {
        kind: "method",
        decorators: [_object.action],
        key: "changeTenant",
        value: function changeTenant(tenant) {
          if (tenant.id === CROSS_TENANTS_ID) {
            SessionManager.switchToCrossTenants();
          } else {
            SessionManager.switchTenant(tenant.id);
          }

          Ember.set(this, 'selectedTenant', tenant);
        }
      }]
    };
  }, Ember.Controller);
  _exports.default = AppController;
});