define("monitor-ui/routes/app/dashboard", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppDashboardRoute extends Ember.Route {}

  _exports.default = AppDashboardRoute;
});