define("monitor-ui/components/question/likert-scale", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/component"], function (_exports, _decorate2, _component) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let QuestionLikertScaleComponent = (0, _decorate2.default)([(0, _component.tagName)('')], function (_initialize, _EmberComponent) {
    class QuestionLikertScaleComponent extends _EmberComponent {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: QuestionLikertScaleComponent,
      d: []
    };
  }, Ember.Component);
  _exports.default = QuestionLikertScaleComponent;
});