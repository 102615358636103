define("monitor-ui/services/current-user", ["exports", "@babel/runtime/helpers/esm/decorate", "@ember-decorators/service"], function (_exports, _decorate2, _service) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  let CurrentUserService = (0, _decorate2.default)(null, function (_initialize, _EmberService) {
    class CurrentUserService extends _EmberService {
      constructor(...args) {
        super(...args);

        _initialize(this);
      }

    }

    return {
      F: CurrentUserService,
      d: [{
        kind: "field",
        decorators: [_service.inject],
        key: "session",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "store",
        value: void 0
      }, {
        kind: "field",
        decorators: [_service.inject],
        key: "metrics",
        value: void 0
      }, {
        kind: "method",
        key: "load",
        value: function load() {
          let userId = this.get('session.data.authenticated.user_id');

          if (!Ember.isEmpty(userId)) {
            return this.get('store').findRecord('user', userId).then(user => {
              this.set('user', user);
              const companyRef = user.belongsTo('company');
              const data = {
                distinctId: userId,
                name: user.fullName,
                email: user.email
              };

              if (companyRef) {
                const companyId = user.belongsTo('company').id();

                if (companyId) {
                  data['company'] = {
                    name: user.companyName,
                    id: user.belongsTo('company').id()
                  };
                }
              }

              this.metrics.identify(data);
            });
          } else {
            return Ember.RSVP.resolve();
          }
        }
      }]
    };
  }, Ember.Service);
  _exports.default = CurrentUserService;
});