define("monitor-ui/models/indicator", ["exports", "ember-data"], function (_exports, _emberData) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const {
    Model,
    attr,
    belongsTo,
    hasMany
  } = _emberData.default;

  var _default = Model.extend({
    name: attr(),
    name_de: attr(),
    dimension: belongsTo('dimension', {
      inverse: 'indicators'
    }),
    company: belongsTo('company', {
      inverse: 'indicators'
    }),
    surveyQuestions: hasMany('survey-question', {
      inverse: 'indicator'
    })
  });

  _exports.default = _default;
});