define("monitor-ui/routes/recover", ["exports", "ember-ajax/errors", "monitor-ui/config/environment"], function (_exports, _errors, _environment) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.Route.extend({
    ajax: Ember.inject.service(),
    notify: Ember.inject.service(),

    model() {
      return Ember.Object.extend({
        email: null
      }).create();
    },

    validateEmail(email) {
      const re = /\S+@\S+\.\S+/;
      return re.test(email);
    },

    actions: {
      checkEmail(model) {
        const email = model.email;

        if (email && this.validateEmail(email)) {
          const ajax = this.ajax;
          ajax.post(_environment.default.backendAPI + '/api/recover-password/', {
            data: {
              email: email,
              app: 'monitor'
            }
          }).then(response => {
            if (response && response.valid) {
              this.set('emailChecked', true);
              this.notify.success('A recovery email was sent. Please check your email.');
              this.transitionTo('login');
            } else {
              // this.get('notify').error(this.get('intl').t('notifyMessages.invalidUser').toString());
              this.notify.error('Invalid user');
            }
          }).catch(response => {
            if (response instanceof _errors.ForbiddenError) {
              // this.get('notify').error(this.get('intl').t('notifyMessages.errorOccurredTryAgain').toString());
              this.notify.error('An error occurred please try again later');
            } else if (response instanceof _errors.AjaxError) {
              // this.get('notify').error(this.get('intl').t('notifyMessages.invalidUser').toString());
              this.notify.error('Invalid user');
            }
          });
        } else {
          // this.get('notify').error(this.get('intl').t('notifyMessages.provideValidEmail').toString());
          this.notify.error('Please provide valid email');
        }
      }

    }
  });

  _exports.default = _default;
});