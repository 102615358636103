define("monitor-ui/templates/head", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  var _default = Ember.HTMLBars.template({
    "id": "m0P94q2i",
    "block": "{\"symbols\":[],\"statements\":[[7,\"title\"],[9],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"companySettings\",\"title\"]]],null,{\"statements\":[[0,\"    \"],[1,[23,[\"model\",\"companySettings\",\"title\"]],false],[0,\"\\n\"]],\"parameters\":[]},{\"statements\":[[0,\"    Apadua - Project performance monitoring\\n\"]],\"parameters\":[]}],[10],[0,\"\\n\"],[4,\"if\",[[23,[\"model\",\"companySettings\",\"id\"]]],null,{\"statements\":[[0,\"  \"],[7,\"link\"],[11,\"rel\",\"stylesheet\"],[12,\"href\",[28,[\"/api/company-css/\",[23,[\"model\",\"companySettings\",\"id\"]],\".css/\"]]],[9],[10],[0,\"\\n\\n\"]],\"parameters\":[]},null]],\"hasEval\":false}",
    "meta": {
      "moduleName": "monitor-ui/templates/head.hbs"
    }
  });

  _exports.default = _default;
});