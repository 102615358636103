define("monitor-ui/controllers/app/account/password", ["exports"], function (_exports) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;

  class AppAccountPasswordController extends Ember.Controller {
    constructor(...args) {
      super(...args);
      this.passwordData = Ember.Object.extend({
        currentPassword: null,
        password: null,
        retypePassword: null
      }).create();
    }

  }

  _exports.default = AppAccountPasswordController;
});